import { Entity, baseConfig } from '@oproma/prividox-store';
import { IFrame } from './iframe.component';

type PdfFilePreviewerProps = {
  entity: Entity;
  style: React.CSSProperties;
};

const createPreviewFileLink = (entity: Entity) => {
  // return `${baseConfig.basePath}/download/${entityId}?vnum=${version}&preview=true`;
  // TODO: Determine whether or not to have redaction populaated
  return `../pdfviewer/web/viewer.html?file=${encodeURIComponent(
    `${baseConfig.basePath}/download/${entity.id}?preview=true`,
  )}#locale=en-CA&name=${entity.name}&redactions=true`;
};

export const PdfFilePreviewer = ({ entity, style }: PdfFilePreviewerProps) => {
  switch (entity?.contentType) {
    case 'application/pdf':
      return (
        <div style={{ ...style, display: 'flex', flexDirection: 'column' }}>
          <IFrame
            id="pdf-previewer"
            ariaLabel="document"
            allowFullScreen
            url={createPreviewFileLink(entity)}
            styles={{
              border: 'none',
              width: '100%',
              height: '100% !important',
              flexGrow: 1,
            }}
          />
        </div>
      );
    default:
      return <>Preview couldn't not be generated</>;
  }
};
