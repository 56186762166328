/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthFooter,
  AuthLayout,
  AuthLogo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Head,
  Icon,
  PreviewCard,
  retrieveApiErrorMessage,
  useResetPasswordForm,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  AuthErrorCodesEnum,
  HashActionsEnum,
  setCompletedResetPassword,
  setResetPasswordEmailDispatched,
  useAppDispatch,
} from '@oproma/prividox-store';
import { Link, useNavigate } from '@oproma/router';
import { Buffer } from 'buffer';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Alert, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    completedPasswordReset,
    confirmPasswordRef,
    displayConfirmPasswordText,
    displayPasswordText,
    emailRef,
    error,
    errors,
    handleSubmit,
    loading,
    onFormSubmit,
    passwordRef,
    passwordReset,
    receivedPasswordResetConfirmationDetails,
    registerConfirmPassword,
    registerEmail,
    registerPassword,
    resetPasswordEmailDispatched,
    setReceivedPasswordResetConfirmationDetailsStatus,
    setValue,
    toggleDisplayingConfirmPasswordText,
    toggleDisplayingPasswordText,
  } = useResetPasswordForm();

  useEffect(() => {
    const processHash = () => {
      const url = new URL(window.location.href);
      const hash = url.hash.substring(1);
      if (hash) {
        const [action, email, code] = hash.split('&');
        if (action === HashActionsEnum.RESET_PASSWORD) {
          const decodedEmail = Buffer.from(email, 'base64');
          const passwordResetCode = Buffer.from(code, 'base64');

          setValue('email', decodedEmail.toString());
          setValue('passwordResetCode', passwordResetCode.toString());
          return setReceivedPasswordResetConfirmationDetailsStatus(true);
        }
      }
      setReceivedPasswordResetConfirmationDetailsStatus(false);
    };

    processHash();
  }, []);

  useEffect(() => {
    if (passwordReset && !completedPasswordReset) {
      toast.success(t('AUTH.RESET_PASSWORD.PASSWORD_RESET_COMPLETED'));
      dispatch(setCompletedResetPassword(true));
      navigate('/auth/login', { replace: true });
    }
  }, [passwordReset, completedPasswordReset]);

  if (error?.code === AuthErrorCodesEnum.RESET_PASSWORD_CONFIRMATION_FAILED) {
    toast.error(t('AUTH.RESET_PASSWORD.RESET_FAILED'));
    toast.error('Failed to reset password. Please try again.');
  }

  return (
    <>
      <Head heading={t('AUTH.RESET_PASSWORD.TITLE')} />
      <AuthLayout>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <AuthLogo />
          {resetPasswordEmailDispatched &&
          !receivedPasswordResetConfirmationDetails ? (
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">
                  {t('AUTH.RESET_PASSWORD.EMAIL_DISPATCHED.HEADING')}
                </BlockTitle>
                <BlockDes className="text-success">
                  <p>{t('AUTH.RESET_PASSWORD.EMAIL_DISPATCHED.BODY')}</p>
                  <Link
                    className="link link-primary link-sm"
                    to="/auth/reset-password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(setResetPasswordEmailDispatched(false));
                    }}
                  >
                    {t('AUTH.RESET_PASSWORD.REDO_FLOW')}
                  </Link>
                </BlockDes>
              </BlockContent>
            </BlockHead>
          ) : (
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h5">
                    {t('AUTH.RESET_PASSWORD.HEADING')}
                  </BlockTitle>
                  <BlockDes>
                    <p>
                      {receivedPasswordResetConfirmationDetails
                        ? t('AUTH.RESET_PASSWORD.FORM.ENTER_PASSWORD')
                        : t('AUTH.RESET_PASSWORD.FORM.ENTER_EMAIL')}
                    </p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {(error?.code === AuthErrorCodesEnum.RESET_PASSWORD_FAILED ||
                error?.code ===
                  AuthErrorCodesEnum.RESET_PASSWORD_CONFIRMATION_FAILED) && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" />{' '}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(retrieveApiErrorMessage(error.message)),
                      }}
                    ></p>
                  </Alert>
                </div>
              )}
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                {receivedPasswordResetConfirmationDetails ? (
                  <>
                    <FormGroup>
                      <div className="form-label-group">
                        <Label for="password">
                          {t('AUTH.RESET_PASSWORD.FORM.PASSWORD')}
                        </Label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          href="#password"
                          onClick={toggleDisplayingPasswordText}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            displayPasswordText ? 'is-hidden' : 'is-shown'
                          }`}
                        >
                          <Icon
                            name="eye"
                            className="passcode-icon icon-show"
                          ></Icon>

                          <Icon
                            name="eye-off"
                            className="passcode-icon icon-hide"
                          ></Icon>
                        </a>
                        <Input
                          type={displayPasswordText ? 'text' : 'password'}
                          id="password"
                          placeholder={t('AUTH.RESET_PASSWORD.FORM.PASSWORD')}
                          autoComplete="off"
                          className={`form-control-lg ${
                            displayPasswordText ? 'is-hidden' : 'is-shown'
                          }`}
                          innerRef={passwordRef}
                          {...registerPassword}
                        />
                        {errors.password && (
                          <span className="invalid">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="form-label-group">
                        <Label for="confirmPassword">
                          {t('AUTH.RESET_PASSWORD.FORM.CONFIRM_PASSWORD')}
                        </Label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          href="#confirm-password"
                          onClick={toggleDisplayingConfirmPasswordText}
                          className={`form-icon lg form-icon-right passcode-switch ${
                            displayConfirmPasswordText
                              ? 'is-hidden'
                              : 'is-shown'
                          }`}
                        >
                          <Icon
                            name="eye"
                            className="passcode-icon icon-show"
                          ></Icon>

                          <Icon
                            name="eye-off"
                            className="passcode-icon icon-hide"
                          ></Icon>
                        </a>
                        <Input
                          id="confirmPassword"
                          type={
                            displayConfirmPasswordText ? 'text' : 'password'
                          }
                          autoComplete="off"
                          placeholder={t(
                            'AUTH.RESET_PASSWORD.FORM.CONFIRM_PASSWORD',
                          )}
                          className={`form-control-lg ${
                            displayConfirmPasswordText
                              ? 'is-hidden'
                              : 'is-shown'
                          }`}
                          innerRef={confirmPasswordRef}
                          {...registerConfirmPassword}
                        />
                        {errors.confirmPassword && (
                          <span className="invalid">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </div>
                    </FormGroup>
                  </>
                ) : (
                  <FormGroup>
                    <div className="form-label-group">
                      <Label className="form-label" for="email">
                        {t('AUTH.RESET_PASSWORD.FORM.EMAIL')}
                      </Label>
                    </div>
                    <Input
                      id="email"
                      type="email"
                      className="form-control-lg"
                      placeholder={t('AUTH.RESET_PASSWORD.FORM.EMAIL')}
                      innerRef={emailRef}
                      {...registerEmail}
                    />
                    {errors.email && (
                      <p className="invalid">
                        {t(errors.email.message as string)}
                      </p>
                    )}
                  </FormGroup>
                )}
                <div className="form-group">
                  <Button
                    size="lg"
                    className="btn-block"
                    type="submit"
                    color="primary"
                  >
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      t('AUTH.RESET_PASSWORD.BUTTON')
                    )}
                  </Button>
                </div>
              </Form>
              <div className="form-note-s2 pt-4 text-center">
                <Link to="/auth/login">
                  <strong>{t('COMMON.BACK_TO_LOGIN')}</strong>
                </Link>
              </div>
            </PreviewCard>
          )}
        </Block>
        <AuthFooter />
      </AuthLayout>
    </>
  );
};

export default ResetPasswordPage;
