import {
  EnableBundleRequest,
  GetWorkspaceActivityRequest,
  UpdateWorkspacePlanRequest,
} from '@oproma/prividox-orchestration-open-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { find, flattenDeep, values } from 'lodash';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import {
  extractApiError,
  metadataService,
  reportsService,
  workspacesService,
} from '../api';
import { getPaymentTransactions } from '../finance';
import { getWorkspaceGroups } from '../groups';
import { AppState } from '../store';
import { getWorkspaces } from '../workspaces';
import { PatchWorkspaceNamePayload } from '../workspaces/types';
import {
  Deletion,
  IWorkspaceState,
  WorkspaceErrorCodesEnum,
  WorkspaceMetadata,
} from './types';

const initialState: IWorkspaceState = {
  name: null,
  bundles: [],
  metadata: null,
  diskUsage: null,
  accessLevel: null,
  plan: null,
  displayMFAModal: false,
  displayBundlesModal: false,
  displayChangePlanModal: false,
  displayDisableMFAModel: false,
  displayEditDescriptionModal: false,
  loading: false,
  error: null,
  deletedEntities: [],
  activity: [],
  dashboardTitle: '',
  dashboardDescription: '',
};

export const getMetadataKey = createAsyncThunk(
  '@@workspace/getMetadataKey',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const response = await metadataService.fetchMetadataKey<any>(
        workspaceId,
        'dashboard',
      );

      let parsedValue;
      try {
        parsedValue = JSON.parse(response.value);
      } catch (e) {
        throw new Error('Failed to parse metadata value');
      }

      // Extract the description from parsed value
      const descriptionItem = parsedValue.find((section: any[]) =>
        section.some((item: any) => item.name === 'paragraph'),
      );
      const description = descriptionItem
        ? descriptionItem.find((item: any) => item.name === 'paragraph')
            .values[0]
        : '';

      // Extract the title from parsed value
      const titleItem = parsedValue.find((section: any[]) =>
        section.some((item: any) => item.name === 'title'),
      );
      const title = titleItem
        ? titleItem.find((item: any) => item.name === 'title').values[0]
        : '';

      return { workspaceId, title, description };
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get key metadata.');
    }
  },
);

export const getWorkspaceMetadata = createAsyncThunk(
  '@@workspace/getWorkspaceMetadata',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const response = await metadataService.fetchMetadata<WorkspaceMetadata>(
        workspaceId,
      );
      if (!response.root)
        return rejectWithValue(`Failed to find root folder for ${response.id}`);
      const rootFolderMetadataResponse = await metadataService.fetchMetadata<
        Pick<WorkspaceMetadata, 'created' | 'modified'>
      >(response.root);

      return {
        ...response,
        created: rootFolderMetadataResponse.created,
        modified: rootFolderMetadataResponse.modified,
      };
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace metadata.');
    }
  },
);

export const getWorkspaceBundles = createAsyncThunk(
  '@@workspace/getWorkspaceBundles',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const response = await workspacesService.fetchBundles(workspaceId);
      return response.items ?? [];
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace bundles.');
    }
  },
);

export const fetchAccessLevelForUser = createAsyncThunk(
  '@@workspace/fetchAccessLevelForUser',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const response = await workspacesService.fetchAccessLevelForUser(
        workspaceId,
      );
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace access level for user.');
    }
  },
);

export const getWorkspacePlan = createAsyncThunk(
  '@@workspace/getWorkspacePlan',
  async (workspaceId: string, { getState, rejectWithValue }) => {
    try {
      const workspacePlan = await workspacesService.fetchWorkspacePlan(
        workspaceId,
      );
      const { pricingPlans } = (getState() as AppState).finance;

      // Get every plan and flatten them
      const plans = flattenDeep(
        values(pricingPlans).map((plan) => values(plan)),
      );

      // Find the matching plan
      const foundPlan = find(plans, ['id', workspacePlan.id]);

      if (!foundPlan) {
        return rejectWithValue('Failed to find workspace plan.');
      }

      return foundPlan;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace plan details.');
    }
  },
);

export const getWorkspaceDiskUsage = createAsyncThunk(
  '@@workspace/getWorkspaceDiskUsage',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const response = await reportsService.fetchDiskUsage(workspaceId);
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to get workspace disk usage.');
    }
  },
);

export const editWorkspacePlan = createAsyncThunk(
  '@@workspace/editWorkspacePlan',
  async (
    { workspaceId, updatePlanSpec }: UpdateWorkspacePlanRequest,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await workspacesService.patchWorkspacePlan({
        workspaceId,
        updatePlanSpec,
      });
      dispatch(getWorkspaces());
      dispatch(getWorkspacePlan(workspaceId));
      dispatch(getPaymentTransactions({}));
      toast.success('Plan edit was completed');
      return response;
    } catch (e) {
      console.error(e);
      toast.error(
        'Failed to edit workspace bundle. Downgrading is not possible. Please try again',
      );
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editWorkspaceBundle = createAsyncThunk(
  '@@workspace/editWorkspaceBundle',
  async (payload: EnableBundleRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await workspacesService.patchWorkspaceBundle(payload);
      toast.success('Bundle edit was completed');
      dispatch(getWorkspaceBundles(payload.workspaceId));
      return response;
    } catch (e) {
      console.error(e);
      toast.error('Failed to edit workspace bundle. Please try again');
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const deleteWorkspace = createAsyncThunk(
  '@@workspace/deleteWorkspace',
  async (workspaceId: string, { rejectWithValue, dispatch }) => {
    try {
      await workspacesService.deleteWorkspace(workspaceId);
      toast.success(t('WORKSPACES.REMOVE_WORKSPACE.TOAST'));
      dispatch(getWorkspaces());
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editWorkspaceName = createAsyncThunk(
  '@@workspace/patchWorkspaceName ',
  async (
    { workspaceId, workspaceName }: PatchWorkspaceNamePayload,
    { rejectWithValue, dispatch },
  ) => {
    try {
      await workspacesService.patchWorkspaceName(workspaceId, workspaceName);
      toast.success('Workspace name has been changed!');
      dispatch(getWorkspaces());
      dispatch(getWorkspaceMetadata(workspaceId));
      dispatch(getWorkspaceGroups({ workspaceId }));
      return workspaceName;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getWorkspaceActivity = createAsyncThunk(
  '@@workspace/getWorkspaceActivity',
  async (
    {
      projectId,
      start,
      end,
    }: { projectId: string; start?: number; end?: number },
    { rejectWithValue },
  ) => {
    try {
      const params: GetWorkspaceActivityRequest = { project: projectId };
      params.start = start ?? 0;
      params.end = end ?? Date.now();
      const response = await reportsService.fetchWorkspaceActivity(params);
      return response.items;
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

export const patchDashboardTitle = createAsyncThunk(
  '@@workspace/patchDashboardTitle',
  async (
    { entity, title }: { entity: string; title: string },
    { rejectWithValue },
  ) => {
    try {
      const result = await metadataService.patchDashboardMetadata(
        entity,
        'title',
        title,
      );
      return { entity, title: result };
    } catch (e) {
      return rejectWithValue('Failed to patch dashboard title.');
    }
  },
);

export const patchDashboardDescription = createAsyncThunk(
  '@@workspace/patchDashboardDescription',
  async (
    { entity, description }: { entity: string; description: string },
    { rejectWithValue },
  ) => {
    try {
      const result = await metadataService.patchDashboardMetadata(
        entity,
        'paragraph',
        description,
      );
      return { entity, description: result };
    } catch (e) {
      return rejectWithValue('Failed to patch dashboard paragraph.');
    }
  },
);

const workspaceSlice = createSlice({
  name: '@@workspace',
  initialState,
  reducers: {
    toggleDisplayBundlesModal: (state) => {
      state.displayBundlesModal = !state.displayBundlesModal;
    },
    toggleDisplayChangePlanModal: (state) => {
      state.displayChangePlanModal = !state.displayChangePlanModal;
    },
    toggleDisplayEditDescriptionModal: (state) => {
      state.displayEditDescriptionModal = !state.displayEditDescriptionModal;
    },
    toggleDisplayMFAModel: (state) => {
      state.displayMFAModal = !state.displayMFAModal;
    },
    toggleDisplayDisableMFAModel: (state) => {
      state.displayDisableMFAModel = !state.displayDisableMFAModel;
    },
    trackDeletedEntity: (state, action) => {
      const [entityId, userId, date] = action.payload;
      const newDeletion: Deletion = {
        entityId,
        userId: userId,
        deletedAt: date,
      };
      state.deletedEntities.push(newDeletion);
    },
    removeDeletedEntity: (state, action) => {
      state.deletedEntities = state.deletedEntities.filter(
        (entity) => entity.entityId !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editWorkspaceName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editWorkspaceName.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.EDIT_WORKSPACE_NAME_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editWorkspaceName.fulfilled, (state, action) => {
        state.loading = false;
        state.name = action.payload;
        state.error = null;
      });

    builder
      .addCase(deleteWorkspace.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWorkspace.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.DELETE_WORKSPACE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getWorkspacePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspacePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_PLAN_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspacePlan.fulfilled, (state, action) => {
        state.plan = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceBundles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceBundles.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_BUNDLES_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceBundles.fulfilled, (state, action) => {
        state.bundles = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(fetchAccessLevelForUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAccessLevelForUser.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_BUNDLES_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(fetchAccessLevelForUser.fulfilled, (state, action) => {
        state.accessLevel = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceMetadata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_METADATA_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceMetadata.fulfilled, (state, action) => {
        state.metadata = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceDiskUsage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceDiskUsage.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_DISK_USAGE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getWorkspaceDiskUsage.fulfilled, (state, action) => {
        state.diskUsage = action.payload;
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(editWorkspacePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editWorkspacePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.EDIT_WORKSPACE_PLAN_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editWorkspacePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.displayChangePlanModal = false;
        state.error = null;
      });
    builder
      .addCase(editWorkspaceBundle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editWorkspaceBundle.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.EDIT_WORKSPACE_BUNDLE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editWorkspaceBundle.fulfilled, (state, action) => {
        state.loading = false;
        state.displayBundlesModal = false;
        state.error = null;
      });
    builder
      .addCase(getMetadataKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMetadataKey.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.EDIT_WORKSPACE_BUNDLE_FAILED, // change this
          message: action.payload as string,
        };
      })
      .addCase(getMetadataKey.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.dashboardDescription = action.payload.description;
        state.dashboardTitle = action.payload.title;
      });
    builder
      .addCase(getWorkspaceActivity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceActivity.fulfilled, (state, action) => {
        state.activity = action.payload ?? [];
        state.loading = false;
      })
      .addCase(getWorkspaceActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: WorkspaceErrorCodesEnum.GET_WORKSPACE_ACTIVITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(patchDashboardDescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(patchDashboardDescription.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardDescription = action.payload.description;
        // state.paragraph = action.payload.newParagraph;
      })
      .addCase(patchDashboardDescription.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.description as string;
      });
    builder
      .addCase(patchDashboardTitle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(patchDashboardTitle.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardTitle = action.payload.title;
      })
      .addCase(patchDashboardTitle.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.description as string;
      });
  },
});

export const {
  toggleDisplayBundlesModal,
  toggleDisplayChangePlanModal,
  toggleDisplayEditDescriptionModal,
  toggleDisplayMFAModel,
  toggleDisplayDisableMFAModel,
  trackDeletedEntity,
  removeDeletedEntity,
} = workspaceSlice.actions;

export const workspaceReducer = workspaceSlice.reducer;
