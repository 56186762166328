import {
  LayoutThemeTypeEnum,
  ThemeModeEnum,
  setDisplaySidebar,
  setMobileMode,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import clsx from 'clsx';
import { useEffect } from 'react';
import { AuthCheck } from '../auth';
import { Footer } from './footer.component';
import { Header } from './header.component';
import { Sidebar } from './sidebar.component';

interface LayoutProps {
  children: React.ReactNode;
  preview?: boolean;
}

export const Layout = ({ children, preview = false }: LayoutProps) => {
  const { entityId } = useParams();
  const dispatch = useAppDispatch();
  const {
    layoutThemeType,
    sidebarThemeType,
    displaySidebar,
    headerThemeType,
    themeMode,
    mobileMode,
  } = useAppSelector((state) => state.layout);

  useEffect(() => {
    viewChange();
    window.addEventListener('load', viewChange);
    window.addEventListener('resize', viewChange);
    return () => {
      window.removeEventListener('load', viewChange);
      window.removeEventListener('resize', viewChange);
    };
  }, []);

  useEffect(() => {
    document.body.className = clsx(
      'nk-body has-apps-sidebar has-sidebar no-touch nk-nio-theme',
      {
        'dark-mode': themeMode === ThemeModeEnum.DARK,
      },
    );

    if (layoutThemeType === LayoutThemeTypeEnum.DEFAULT) {
      document.body.classList.add('ui-default');
      document.body.classList.remove('ui-clean', 'ui-shady', 'ui-softy');
    }
    if (layoutThemeType === LayoutThemeTypeEnum.CLEAN) {
      document.body.classList.add('ui-clean');
      document.body.classList.remove('ui-default', 'ui-shady', 'ui-softy');
    }
    if (layoutThemeType === LayoutThemeTypeEnum.SHADY) {
      document.body.classList.add('ui-shady');
      document.body.classList.remove('ui-default', 'ui-clean', 'ui-softy');
    }
    if (layoutThemeType === LayoutThemeTypeEnum.SOFTY) {
      document.body.classList.add('ui-softy');
      document.body.classList.remove('ui-default', 'ui-clean', 'ui-shady');
    }
  }, [layoutThemeType, themeMode]);

  useEffect(() => {
    if (displaySidebar) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
    if (!displaySidebar) {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [displaySidebar]);

  const toggleSidebar = (ev: React.MouseEvent) => {
    ev.preventDefault();
    dispatch(setDisplaySidebar(!displaySidebar));
  };

  const viewChange = () => {
    if (window.innerWidth < 1200) {
      dispatch(setMobileMode(true));
    } else {
      dispatch(setMobileMode(false));
      dispatch(setDisplaySidebar(false));
    }
  };

  const sidebarClass = clsx({
    'nk-sidebar-mobile': mobileMode,
    'nk-sidebar-active': displaySidebar && mobileMode,
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {preview ? (
        <div className="nk-app-root">
          <div className="nk-main">
            <Sidebar
              sidebarToggle={toggleSidebar}
              fixed
              mobileView={mobileMode}
              theme={sidebarThemeType}
              className={sidebarClass}
              preview
            />
            {displaySidebar && mobileMode && (
              <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>
            )}
            <div className="nk-wrap">
              <Header
                sidebarToggle={toggleSidebar}
                setVisibility={(displaySidebar: boolean) =>
                  dispatch(setDisplaySidebar(displaySidebar))
                }
                fixed
                theme={headerThemeType}
                preview
              />
              {children}
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <AuthCheck>
          <div className="nk-app-root">
            <div className="nk-main">
              <Sidebar
                sidebarToggle={toggleSidebar}
                fixed
                mobileView={mobileMode}
                theme={sidebarThemeType}
                className={sidebarClass}
              />
              {displaySidebar && mobileMode && (
                <div
                  className="nk-sidebar-overlay"
                  onClick={toggleSidebar}
                ></div>
              )}
              <div className="nk-wrap">
                <Header
                  sidebarToggle={toggleSidebar}
                  setVisibility={(displaySidebar: boolean) =>
                    dispatch(setDisplaySidebar(displaySidebar))
                  }
                  fixed
                  theme={headerThemeType}
                />
                {children}
                <Footer />
              </div>
            </div>
          </div>
        </AuthCheck>
      )}
    </>
  );
};
