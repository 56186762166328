import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  Group,
  GroupInfo,
  SetPermissionRequest,
} from '@oproma/prividox-orchestration-open-api';
import {
  WorkspaceMember,
  editMemberPermission,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import * as yup from 'yup';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from '../../block.component';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';

type PermissionOption = {
  value: string;
  label: string;
};

const permissionOptions: PermissionOption[] = [
  { value: 'NO_ACCESS', label: 'NO_CONTROL' },
  { value: 'INHERITED', label: 'INHERITED' },
  { value: 'VIEW', label: 'VIEW' },
  { value: 'MODIFY', label: 'EDIT' },
  { value: 'FULL_CONTROL', label: 'FULL_CONTROL' },
];

const workspacePermissionsSchema = yup.object({
  permissions: yup
    .array()
    .of(
      yup.object({
        type: yup.string().required(),
        user: yup.string().required(),
        entity: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .default([]),
});

type WorkspacePermissionsFormValues = yup.InferType<
  typeof workspacePermissionsSchema
>;

type WorkspacePermissionsTabProps = {
  workspaceId: string | undefined;
};

export const WorkspaceOptionsPermissionsTab = ({
  workspaceId,
}: WorkspacePermissionsTabProps) => {
  const dispatch = useAppDispatch();
  const { members } = useAppSelector((state) => state.members);
  const { groups } = useAppSelector((state) => state.groups);
  const { permissions } = useAppSelector((state) => state.permissions);
  const { t } = useTranslation();

  const { register, handleSubmit, setValue, watch, reset } =
    useForm<WorkspacePermissionsFormValues>({
      resolver: yupResolver(workspacePermissionsSchema),
      defaultValues: {
        permissions: permissions.map((perm) => ({
          type: perm.permissionType,
          user: perm.entityId,
          entity: workspaceId,
          value: perm.permission,
        })),
      },
    });

  const watchedPermissions = watch('permissions');

  useEffect(() => {
    reset({
      permissions: permissions.map((perm) => ({
        type: perm.permissionType,
        user: perm.entityId,
        entity: workspaceId,
        value: perm.permission,
      })),
    });
  }, [permissions]);

  const handleMemberPermissionChange = (
    type: string,
    member: WorkspaceMember,
    optionValue: string,
  ) => {
    const existingIndex = watchedPermissions?.findIndex(
      (perm) => perm.type === type && perm.user === member.id,
    );

    if (existingIndex !== -1) {
      const updatedPermissions = [...watchedPermissions];
      updatedPermissions[existingIndex].value = optionValue;
      setValue('permissions', updatedPermissions);
    } else {
      setValue('permissions', [
        ...watchedPermissions,
        {
          type,
          user: member.id!,
          entity: workspaceId!,
          value: optionValue,
        },
      ]);
    }
  };

  const onFormSubmit: SubmitHandler<WorkspacePermissionsFormValues> = async (
    payload,
  ) => {
    payload.permissions.map((permission) =>
      dispatch(
        editMemberPermission({
          ...permission,
          body: permission.value,
        } as SetPermissionRequest),
      ),
    );
  };

  const handleGroupPermissionChange = (
    type: string,
    group: Group,
    optionValue: string,
  ) => {
    const existingIndex = watchedPermissions?.findIndex(
      (perm) => perm.type === type && perm.user === group.id,
    );

    if (existingIndex !== -1) {
      const updatedPermissions = [...watchedPermissions];
      updatedPermissions[existingIndex].value = optionValue;
      setValue('permissions', updatedPermissions);
    } else {
      setValue('permissions', [
        ...watchedPermissions,
        {
          type,
          user: group.id!,
          entity: workspaceId!,
          value: optionValue,
        },
      ]);
    }
  };

  const renderMemberRow = (member: WorkspaceMember, type: string) => {
    const permissionIndex = watchedPermissions.findIndex(
      (perm) => perm.type === type && perm.user === member.id,
    );
    return (
      <Row className="g-3 align-center" key={member.id}>
        <Col lg="5">
          <div className="form-group">
            <label className="form-label">{member.email}</label>
            <span className="form-note">
              {t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.1.DESCRIPTION')}
            </span>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group">
            <ul className="custom-control-group g-3 align-center flex-wrap">
              {permissionOptions.map((option) => (
                <li key={option.value}>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      value={option.value}
                      id={`${type}-${member.id}-${option.value}`}
                      {...register(`permissions.${permissionIndex}.value`)}
                      name={`${type}-${member.id}-${option.value}`}
                      onClick={() =>
                        handleMemberPermissionChange(type, member, option.value)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${type}-${member.id}-${option.value}`}
                    >
                      {t(
                        `WORKSPACE_OPTIONS.TABS.2.CHECK_OPTIONS.${option.label}`,
                      )}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };

  const renderGroupRow = (group: GroupInfo, type: string) => {
    const permissionIndex = watchedPermissions.findIndex(
      (perm) => perm.type === type && perm.user === group.id,
    );
    return (
      <Row className="g-3 align-center" key={group.id}>
        <Col lg="5">
          <div className="form-group">
            <label className="form-label">
              {group.name} {group.isDefaultGroup && '(Default)'}
            </label>
            <span className="form-note">
              {t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.2.DESCRIPTION', {
                memberCount: group.memberCount,
              })}
            </span>
          </div>
        </Col>
        <Col lg="7">
          <div className="form-group">
            <ul className="custom-control-group g-3 align-center flex-wrap">
              {permissionOptions.map((option) => (
                <li key={option.value}>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      value={option.value}
                      id={`${type}-${group.id}-${option.value}`}
                      {...register(`permissions.${permissionIndex}.value`)}
                      name={`${type}-${group.id}-${option.value}`}
                      onClick={() =>
                        handleGroupPermissionChange(type, group, option.value)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${type}-${group.id}-${option.value}`}
                    >
                      {t(
                        `WORKSPACE_OPTIONS.TABS.2.CHECK_OPTIONS.${option.label}`,
                      )}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Form className="gy-3" onSubmit={handleSubmit(onFormSubmit)}>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">
              {t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.1.HEADING')}
            </BlockTitle>
            <BlockDes>
              <p>{t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.1.SUBHEADING')}</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {members.map((member) => renderMemberRow(member, 'member'))}

      <li className="divider" />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h6">
              {t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.2.HEADING')}
            </BlockTitle>
            <BlockDes>
              <p>{t('WORKSPACE_OPTIONS.TABS.2.OPTIONS.2.HEADING')}</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {/* TODO: Determine if pagination is required or not */}
      {groups.map((group) => renderGroupRow(group, 'group'))}

      <li className="divider" />

      <Row className="g-3">
        {/* <Col lg="7" className="offset-lg-5"> */}
        <div className="form-group mt-2">
          <Button color="primary" size="md" type="submit">
            {t('COMMON.CONFIRM')}
          </Button>
        </div>
        {/* </Col> */}
      </Row>
    </Form>
  );
};
