import {
  Entity,
  setBulkAction,
  setBulkPaste,
  setDisplayBulkDeleteEntity,
  setDisplayBulkDistributeModal,
  setDisplayMoveEntityModal,
} from '@oproma/prividox-store';
import { toast } from 'react-toastify';
import { Alert, Tooltip } from 'reactstrap';
import { BlockHeadContent } from '../block.component';
import { Icon } from '../icon.component';
import { ClipboardCopy } from '../clipboard-copy.component';
import { useFileActions } from '../hooks/use-file-actions.hook';
import { useEffect } from 'react';
import { getSelectedEntitiesText } from '../utils';

export const FileManagerFileActions = () => {
  const {
    tooltips,
    selectedEntities,
    selectedEntityIds,
    displayBulkDistributeModal,
    displayBulkDeleteEntityModal,
    clearSelectedEntities,
    selectorDownload,
    selectorSubscribe,
    selectorArchive,
    generateCopyLink,
    dispatch,
    toggle,
    t,
  } = useFileActions();

  const folderCount = selectedEntities.filter(
    (entity) => entity.type === 'folder',
  ).length;
  const fileCount = selectedEntities.filter(
    (entity) => entity.type === 'file',
  ).length;

  const selectedEntitiesText = getSelectedEntitiesText(
    folderCount,
    fileCount,
    t,
  );

  useEffect(() => {
    if (selectedEntityIds.length >= 1) {
      dispatch(setBulkAction(true));
    } else {
      dispatch(setBulkAction(false));
    }
  }, [selectedEntityIds]);

  if (selectedEntityIds.length >= 1) {
    return (
      <BlockHeadContent>
        <Alert color="primary" className="my-2">
          <div className="nk-ibx-head-actions">
            <a
              href="#item"
              onClick={(ev) => {
                ev.preventDefault();
                clearSelectedEntities();
              }}
              className="btn btn-icon btn-trigger"
            >
              <Icon name="cross"></Icon>
            </a>
            <ul className="nk-ibx-head-tools g-1">
              <li></li>
              <li>
                <p>{selectedEntitiesText}</p>
              </li>
              <li>
                <a
                  href="#item"
                  onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(
                      setDisplayBulkDistributeModal(
                        !displayBulkDistributeModal,
                      ),
                    );
                  }}
                  className="btn btn-icon btn-trigger"
                  id="share"
                >
                  <Icon name="share"></Icon>
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.share}
                  target="share"
                  toggle={() => toggle('share')}
                >
                  {t('TOOLTIP.SHARE')}
                </Tooltip>
              </li>
              <li>
                <a
                  href="#item"
                  id="download"
                  onClick={(ev) => {
                    ev.preventDefault();
                    selectorDownload(ev);
                  }}
                  className="btn btn-icon btn-trigger"
                >
                  <Icon name="download"></Icon>
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.download}
                  target="download"
                  toggle={() => toggle('download')}
                >
                  {t('TOOLTIP.DOWNLOAD')}
                </Tooltip>
              </li>
              <li className="d-none d-sm-block">
                <ClipboardCopy
                  text={selectedEntityIds
                    .map((entityId) => generateCopyLink(entityId ?? 'N/A'))
                    .join(';')}
                  onCopy={() => {
                    toast.success(t('FILE_MANAGER.DROPDOWN.COPIED'));
                  }}
                >
                  <a
                    href="#item"
                    id="copy"
                    onClick={(ev) => {
                      ev.preventDefault();
                      dispatch(setBulkPaste(true));
                    }}
                    className="btn btn-icon btn-trigger"
                  >
                    <Icon name="copy"></Icon>
                  </a>
                </ClipboardCopy>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.copy}
                  target="copy"
                  toggle={() => toggle('copy')}
                >
                  {t('TOOLTIP.COPY')}
                </Tooltip>
              </li>
              <li className="d-none d-sm-block">
                <a
                  href="#item"
                  id="star"
                  onClick={(ev) => {
                    ev.preventDefault();
                    selectedEntities.map((entity: Entity) =>
                      selectorArchive(entity),
                    );
                  }}
                  className={`btn btn-icon btn-trigger`}
                >
                  <Icon name="star"></Icon>
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.star}
                  target="star"
                  toggle={() => toggle('star')}
                >
                  {' '}
                  {t('TOOLTIP.STAR')}
                </Tooltip>
              </li>
              <li className="d-none d-sm-block">
                <a
                  id="trash"
                  href="#item"
                  onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(
                      setDisplayBulkDeleteEntity(!displayBulkDeleteEntityModal),
                    );
                  }}
                  className={`btn btn-icon btn-trigger`}
                >
                  <Icon name="trash"></Icon>
                  <Tooltip
                    placement="top"
                    isOpen={tooltips.trash}
                    target="trash"
                    toggle={() => toggle('trash')}
                  >
                    {t('TOOLTIP.TRASH')}
                  </Tooltip>
                </a>
              </li>
              <li className="d-none d-sm-block">
                <a
                  href="#item"
                  id="subscribe"
                  onClick={(ev) => {
                    ev.preventDefault();
                    selectedEntities.map((entity: Entity) =>
                      selectorSubscribe(entity),
                    );
                  }}
                  className={`btn btn-icon btn-trigger`}
                >
                  {selectedEntities.every((entity) => entity.subscribed) ? (
                    <Icon name="bell"></Icon>
                  ) : (
                    <Icon name="bell-fill"></Icon>
                  )}
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.subscribe}
                  target="subscribe"
                  toggle={() => toggle('subscribe')}
                >
                  {selectedEntities.every((entity) => entity.subscribed)
                    ? t('TOOLTIP.UNSUBSCRIBE')
                    : t('TOOLTIP.SUBSCRIBE')}
                </Tooltip>
              </li>
              <li className="d-none d-sm-block">
                <a
                  href="#item"
                  id="move"
                  onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(setDisplayMoveEntityModal(true));
                  }}
                  className={`btn btn-icon btn-trigger`}
                >
                  <Icon name="forward-arrow" />
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltips.move}
                  target="move"
                  toggle={() => toggle('move')}
                >
                  {t('TOOLTIP.MOVE')}
                </Tooltip>
              </li>
            </ul>
          </div>
        </Alert>
      </BlockHeadContent>
    );
  }
};
