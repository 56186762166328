import { ChangeEvent } from 'react';
import { Button } from './button.component';
import { Icon } from './icon.component';

interface InputNumberProps {
  max?: number;
  min?: number;
  changeBy?: number;
  outline?: boolean;
  color?: string;
  start: number;
  setNumber: (number: number) => void;
  disabled?: boolean;
}

export const InputNumber = ({
  max = Infinity,
  min = -Infinity,
  changeBy = 1,
  outline = false,
  color,
  start: startingNumber,
  setNumber,
  disabled,
}: InputNumberProps) => {
  const handleNumberChange = (
    changeBy: number,
    operation: 'add' | 'subtract',
  ) => {
    if (
      (operation === 'add' && startingNumber !== max) ||
      (operation === 'subtract' && startingNumber !== min)
    ) {
      setNumber(startingNumber + (operation === 'add' ? 1 : -1) * changeBy);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNumber(Number(event.target.value));
  };

  return (
    <div className="form-control-wrap number-spinner-wrap">
      <Button
        outline={outline}
        color={color}
        disabled={startingNumber === min}
        className="btn-icon number-spinner-btn number-minus"
        onClick={(ev) => {
          ev.preventDefault();
          handleNumberChange(changeBy, 'subtract');
        }}
      >
        <Icon name="minus" />
      </Button>
      <input
        type="number"
        className="form-control number-spinner"
        value={startingNumber}
        onChange={handleInputChange}
        max={max}
        min={min}
        disabled={disabled}
      />
      <Button
        outline={outline}
        color={color}
        disabled={startingNumber === max}
        className="btn-icon number-spinner-btn number-plus"
        onClick={(ev) => {
          ev.preventDefault();
          handleNumberChange(changeBy, 'add');
        }}
      >
        <Icon name="plus" />
      </Button>
    </div>
  );
};
