import { yupResolver } from '@hookform/resolvers/yup';
import {
  resetPassword,
  resetPasswordConfirmation,
  setReceivedPasswordResetConfirmationDetails,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

// TODO: Convert into i18n
const resetPasswordSchema = yup
  .object({
    email: yup
      .string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'ERROR.InvalidEmail',
      ),
    // Not required until there is flow to confirm password reset
    password: yup.string().min(12).max(32),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'ERROR.PasswordsMustMatch'),
    passwordResetCode: yup.string(),
  })
  .required();

type ResetPasswordFormValues = yup.InferType<typeof resetPasswordSchema>;

export const useResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const {
    passwordReset,
    completedPasswordReset,
    resetPasswordEmailDispatched,
    receivedPasswordResetConfirmationDetails,
    loading,
    error,
  } = useAppSelector((state) => state.auth);

  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();
  const {
    displayPasswordText: displayConfirmPasswordText,
    toggleDisplayingPasswordText: toggleDisplayingConfirmPasswordText,
  } = useToggleDisplayingPassswordText();

  const {
    register: registerField,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(resetPasswordSchema),
  });

  const { ref: emailRef, ...registerEmail } = registerField('email');
  const { ref: passwordRef, ...registerPassword } = registerField('password');
  const { ref: confirmPasswordRef, ...registerConfirmPassword } =
    registerField('confirmPassword');

  const onFormSubmit: SubmitHandler<ResetPasswordFormValues> = (payload) => {
    if (receivedPasswordResetConfirmationDetails) {
      if (
        !payload.email ||
        !payload.confirmPassword ||
        !payload.passwordResetCode
      ) {
        return;
      }
      dispatch(
        resetPasswordConfirmation({
          email: payload.email,
          pass: payload.confirmPassword,
          token: payload.passwordResetCode,
        }),
      );
    } else {
      if (!payload.email) {
        return;
      }
      dispatch(resetPassword({ email: payload.email }));
    }
  };

  const setReceivedPasswordResetConfirmationDetailsStatus = (
    status: boolean,
  ) => {
    dispatch(setReceivedPasswordResetConfirmationDetails(status));
  };

  return {
    errors,
    emailRef,
    passwordRef,
    confirmPasswordRef,
    onFormSubmit,
    handleSubmit,
    registerEmail,
    registerPassword,
    registerConfirmPassword,
    receivedPasswordResetConfirmationDetails,
    displayPasswordText,
    toggleDisplayingPasswordText,
    displayConfirmPasswordText,
    toggleDisplayingConfirmPasswordText,
    setValue,
    setReceivedPasswordResetConfirmationDetailsStatus,
    passwordReset,
    completedPasswordReset,
    resetPasswordEmailDispatched,
    loading,
    error,
  };
};
