import {
  BlockTitle,
  FileManagerContainer,
  FileManagerNavigationLayout,
  FileManagerTrash,
  Layout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getTrashEntities,
  getWorkspaceMembers,
  useAppDispatch,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect } from 'react';

const FileManagerTrashPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();

  useEffect(() => {
    if (!workspaceId) return;
    dispatch(getTrashEntities(workspaceId as string));
    dispatch(getWorkspaceMembers(workspaceId as string));
  }, [workspaceId]);

  return (
    <Layout>
      <FileManagerContainer>
        <FileManagerNavigationLayout
          title={<BlockTitle page>{t('FILE_MANAGER.TRASH.TITLE')}</BlockTitle>}
          entityFinder
        >
          <FileManagerTrash />
        </FileManagerNavigationLayout>
      </FileManagerContainer>
    </Layout>
  );
};

export default FileManagerTrashPage;
