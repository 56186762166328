import {
  AuthFooter,
  AuthLayout,
  AuthLogo,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Head,
  Icon,
  PreviewCard,
  retrieveApiErrorMessage,
  useLoginForm,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  AuthErrorCodesEnum,
  setCompletedOnboardingStatus,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link, useNavigate } from '@oproma/router';
import { useEffect } from 'react';
import { Alert, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';

const LoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    errors,
    onFormSubmit,
    handleSubmit,
    emailRef,
    passwordRef,
    registerEmail,
    registerPassword,
    displayPasswordText,
    toggleDisplayingPasswordText,
    mfaRef,
    registerMFA,
    displayMFAInput,
  } = useLoginForm();

  const { isAuthenticated, completedOnboarding, loading, error } =
    useAppSelector((state) => state.auth);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (isAuthenticated) {
        // Ensure the dispatch and state update completes
        await dispatch(setCompletedOnboardingStatus());

        if (completedOnboarding) {
          return navigate('/', {
            replace: true,
          });
        } else {
          return navigate('/auth/onboarding', {
            replace: true,
          });
        }
      }
    };
    checkOnboardingStatus();
  }, [isAuthenticated, completedOnboarding]);

  return (
    <>
      <Head heading={t('AUTH.LOGIN.TITLE')} />

      <AuthLayout>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <AuthLogo />
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">{t('AUTH.LOGIN.HEADING')}</BlockTitle>
                <BlockDes>
                  <p>{t('AUTH.LOGIN.SUBHEADING')}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {/* <ul className="nav gx-4 justify-center">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  {t('LOGIN.OAUTH.GOOGLE')}
                </a>
              </li>
            </ul>
            <div className="pb-3 pt-4 text-center">
              <h6 className="overline-title overline-title-sap">
                <span>{t('LOGIN.LOGIN_WITH_EMAIL')}</span>
              </h6>
            </div> */}
            {error?.code === AuthErrorCodesEnum.LOGIN_FAILED && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" />{' '}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(retrieveApiErrorMessage(error.message)),
                    }}
                  />
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <Label className="form-label" for="email">
                    {t('AUTH.LOGIN.FORM.EMAIL')}
                  </Label>
                </div>
                <div className="form-control-wrap">
                  <Input
                    type="text"
                    id="email"
                    placeholder={t('AUTH.LOGIN.FORM.ENTER_EMAIL')}
                    className="form-control-lg"
                    innerRef={emailRef}
                    {...registerEmail}
                    tabIndex={1}
                  />
                  {errors.email && (
                    <span className="invalid">{errors.email.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Label for="password">{t('AUTH.LOGIN.FORM.PASSWORD')}</Label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={toggleDisplayingPasswordText}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      displayPasswordText ? 'is-hidden' : 'is-shown'
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <Input
                    type={displayPasswordText ? 'text' : 'password'}
                    id="password"
                    placeholder={t('AUTH.LOGIN.FORM.ENTER_PASSWORD')}
                    className={`form-control-lg ${
                      displayPasswordText ? 'is-hidden' : 'is-shown'
                    }`}
                    innerRef={passwordRef}
                    {...registerPassword}
                    tabIndex={2}
                  />
                  {errors.password && (
                    <span className="invalid">{errors.password.message}</span>
                  )}
                  <Link
                    className="link link-primary link-sm d-flex flex-row-reverse mt-1 "
                    to="/auth/reset-password"
                    tabIndex={3}
                  >
                    {t('AUTH.LOGIN.FORM.FORGOT_PASSWORD')}
                  </Link>
                </div>
              </FormGroup>
              {displayMFAInput && (
                <FormGroup>
                  <div className="form-label-group">
                    <Label className="form-label" for="email">
                      {t('AUTH.LOGIN.FORM.MFA')}
                    </Label>
                  </div>
                  <div className="form-control-wrap">
                    <Input
                      type="text"
                      id="email"
                      placeholder={t('AUTH.LOGIN.FORM.ENTER_MFA')}
                      className="form-control-lg"
                      innerRef={mfaRef}
                      {...registerMFA}
                      tabIndex={1}
                    />
                    {errors.mfa && (
                      <span className="invalid">{errors.mfa.message}</span>
                    )}
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                  tabIndex={4}
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t('AUTH.LOGIN.BUTTON')
                  )}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 pt-4 text-center">
              {t('AUTH.LOGIN.REGISTER_LINK.TEXT')}{' '}
              <Link
                to="/auth/register"
                tabIndex={5}
                className="link link-primary"
              >
                {t('AUTH.LOGIN.REGISTER_LINK.LINK')}
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </AuthLayout>
    </>
  );
};

export default LoginPage;
