import { yupResolver } from '@hookform/resolvers/yup';
import {
  createDeviceFingerPrint,
  login,
  setRequiresMFA,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToggleDisplayingPassswordText } from './use-toggle-displaying-passsword-text.hook';

export const useLoginForm = () => {
  const dispatch = useAppDispatch();
  const { requiresMFA } = useAppSelector((state) => state.auth);
  const { displayPasswordText, toggleDisplayingPasswordText } =
    useToggleDisplayingPassswordText();
  const [displayMFAInput, setDisplayMFAInput] = useState<boolean | null>(false);

  const loginSchema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().min(12).max(32).required(),
      mfa: requiresMFA ? yup.string().required() : yup.string(),
    })
    .required();

  type LoginFormValues = yup.InferType<typeof loginSchema>;

  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({ resolver: yupResolver(loginSchema) });

  const { ref: emailRef, ...registerEmail } = registerField('email');
  const { ref: passwordRef, ...registerPassword } = registerField('password');
  const { ref: mfaRef, ...registerMFA } = registerField('mfa');

  useEffect(() => {
    dispatch(setRequiresMFA(false));
  }, []);

  useEffect(() => {
    setDisplayMFAInput(requiresMFA);
  }, [requiresMFA]);

  const onFormSubmit: SubmitHandler<LoginFormValues> = ({
    email,
    password,
    mfa,
  }) => {
    const deviceFingerPrint = createDeviceFingerPrint();
    if (!mfa) {
      dispatch(
        login({
          email,
          pass: password,
          fingerprint: deviceFingerPrint,
          exp: 1800000, // expiry time in milliseconds
        }),
      );
    } else {
      dispatch(
        login({
          email,
          pass: password,
          fingerprint: deviceFingerPrint,
          exp: 1800000, // expiry time in milliseconds
          token: mfa,
        }),
      );
    }
  };

  return {
    errors,
    emailRef,
    passwordRef,
    onFormSubmit,
    handleSubmit,
    registerEmail,
    registerPassword,
    displayPasswordText,
    displayMFAInput,
    mfaRef,
    registerMFA,
    setRequiresMFA,
    toggleDisplayingPasswordText,
  };
};
