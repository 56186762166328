import {
  BlockBetween,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  InputSwitch,
  Layout,
  PreferencesHeader,
  PreferencesLayout,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  NotificationPreferenceType,
  NotificationPreferenceTypes,
  editNotificationPreference,
  getNotificationPreferences,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { pick } from 'lodash';
import { Fragment, MouseEvent, useEffect } from 'react';

type GroupedPreference = {
  title: string;
  description: string;
  preferences: { [key: string]: boolean };
};

const NotificationPreferences = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { notificationPreferences } = useAppSelector(
    (state) => state.notifications,
  );

  const getPreferenceTypeLabel = (
    preferenceType: NotificationPreferenceType,
  ) => {
    // TODO: Sync with translation files
    switch (preferenceType) {
      case 'appLinked':
        return t('PREFERENCES.NOTIFICATIONS.APP_LINKED');
      case 'newDevice':
        return t('PREFERENCES.NOTIFICATIONS.NEW_DEVICE');
      case 'projectInvite':
        return t('PREFERENCES.NOTIFICATIONS.PROJECT_INVITE');
      case 'projectRemoved':
        return t('PREFERENCES.NOTIFICATIONS.PROJECT_REMOVED');
      case 'spaceUsage':
        return t('PREFERENCES.NOTIFICATIONS.SPACE_USAGE');
      case 'taskCompleted':
        return t('PREFERENCES.NOTIFICATIONS.TASK.COMPLETED');
      case 'taskCreated':
        return t('PREFERENCES.NOTIFICATIONS.TASK.CREATED');
      case 'taskDeleted':
        return t('PREFERENCES.NOTIFICATIONS.TASK.DELETED');
      case 'taskIncomplete':
        return t('PREFERENCES.NOTIFICATIONS.TASK.INCOMPLETE');
      case 'taskUpdated':
        return t('PREFERENCES.NOTIFICATIONS.TASK.UPDATED');
      default:
        return t('PREFERENCES.DEFAULT');
    }
  };

  // TODO: Move to hook
  const groupNotificationPreferences = (
    notificationPreferences: NotificationPreferenceTypes | null,
  ): GroupedPreference[] => {
    if (!notificationPreferences) {
      return [];
    }
    const groupings = [
      {
        title: t('PREFERENCES.NOTIFICATIONS.GENERAL.TITLE'),
        description: t('PREFERENCES.NOTIFICATIONS.GENERAL.DESCRIPTION'),
        preferences: ['newDevice', 'spaceUsage', 'appLinked'],
      },
      {
        title: t('PREFERENCES.NOTIFICATIONS.TASK_NOTIFICATION.TITLE'),
        description: t(
          'PREFERENCES.NOTIFICATIONS.TASK_NOTIFICATION.DESCRIPTION',
        ),
        preferences: [
          'taskCreated',
          'taskIncomplete',
          'taskCompleted',
          'taskDeleted',
          'taskUpdated',
        ],
      },
      {
        title: t('PREFERENCES.NOTIFICATIONS.WORKSPACE.TITLE'),
        description: t('PREFERENCES.NOTIFICATIONS.WORKSPACE.DESCRIPTION'),
        preferences: ['projectInvite', 'projectRemoved'],
      },
    ];

    return groupings.map((group) => {
      return {
        ...group,
        preferences: pick(notificationPreferences, group.preferences),
      };
    });
  };

  const handleChangingNotificationPreferences = (
    preferenceType: NotificationPreferenceType,
    ev: MouseEvent<HTMLInputElement> | undefined,
  ) => {
    dispatch(
      editNotificationPreference({
        type: preferenceType,
        body: (ev?.target as HTMLInputElement).checked,
      }),
    );
  };

  return groupNotificationPreferences(notificationPreferences).map(
    ({ title, description, preferences }, i) => (
      <Fragment key={i}>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h6">{title}</BlockTitle>
              <BlockDes>
                <p>{description}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <BlockContent>
          <div className="gy-3">
            {Object.entries(preferences).map(
              ([preferenceType, preferenceStatus], i) => (
                <div className="g-item" key={i}>
                  <div className="custom-control custom-switch">
                    <InputSwitch
                      id={`custom-switch-${preferenceType}`}
                      checked={preferenceStatus}
                      label={getPreferenceTypeLabel(
                        preferenceType as NotificationPreferenceType,
                      )}
                      onClick={(ev) => {
                        handleChangingNotificationPreferences(
                          preferenceType as NotificationPreferenceType,
                          ev,
                        );
                      }}
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </BlockContent>
      </Fragment>
    ),
  );
};

// TODO: Move to translation files
const NotificationPreferencesPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationPreferences());
  }, []);

  return (
    <Layout>
      <PreferencesLayout type="notifications">
        <PreferencesHeader type="notifications" />
        <NotificationPreferences />
      </PreferencesLayout>
    </Layout>
  );
};

export default NotificationPreferencesPage;
