import { useTranslation } from '@oproma/i18n';
import {
  deleteFile,
  setDisplayRemoveEntityModal,
  trackDeletedEntity,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';

export const RemoveEntityModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayRemoveEntityModal } = useAppSelector(
    (state) => state.fileManager,
  );
  const { id } = useAppSelector((state) => state.user);

  const lastOpenedEntity = useAppSelector(
    (state) => state.fileManager.lastOpenedEntity,
  );

  const titleKey = lastOpenedEntity?.folderId
    ? 'FILE_MANAGER.FILES.DELETE_FOLDER_CONFIRMATION_TITLE'
    : 'FILE_MANAGER.FILES.DELETE_FILE_CONFIRMATION_TITLE';

  const messageKey = lastOpenedEntity?.folderId
    ? 'FILE_MANAGER.FILES.DELETE_FOLDER_CONFIRMATION_MESSAGE'
    : 'FILE_MANAGER.FILES.DELETE_FILE_CONFIRMATION_MESSAGE';

  const toggleModal = () =>
    dispatch(setDisplayRemoveEntityModal(!displayRemoveEntityModal));

  const onDeleteEntity = (entityId: string | undefined) => {
    if (!workspaceId || !entityId) return;
    dispatch(deleteFile({ entityId, t }));
    dispatch(trackDeletedEntity([entityId, id, new Date()]));
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={displayRemoveEntityModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t(titleKey)}</h5>
          <div className="mt-4">
            <p>{t(messageKey, { entityName: lastOpenedEntity?.name })}</p>
            <Col size="12">
              <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                <li className="me-4">
                  <Button
                    color="primary"
                    onClick={() => {
                      if (lastOpenedEntity?.id) {
                        onDeleteEntity(lastOpenedEntity?.id);
                      } else if (lastOpenedEntity?.fileId) {
                        onDeleteEntity(lastOpenedEntity?.fileId);
                      } else if (lastOpenedEntity?.folderId) {
                        onDeleteEntity(lastOpenedEntity?.folderId);
                      }
                    }}
                  >
                    {t('MEMBERS.REMOVE_MEMBER.CONFIRM')}
                  </Button>
                </li>
                <li>
                  <Button onClick={handleCancel}>
                    {t('MEMBERS.REMOVE_MEMBER.CANCEL')}
                  </Button>
                </li>
              </ul>
            </Col>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
