import { useTranslation } from '@oproma/i18n';
import {
  setDisplayMobileMenu,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from './block.component';
import { Button } from './button.component';
import { Icon } from './icon.component';

type PreferencesHeaderType =
  | 'notifications'
  | 'personal'
  | 'privacy'
  | 'devices';

type PreferencesHeaderProps = {
  type: PreferencesHeaderType;
};

export const PreferencesHeader = ({ type }: PreferencesHeaderProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayMobileMenu } = useAppSelector((state) => state.layout);

  const changeDisplayMobileMenu = (payload: boolean) =>
    dispatch(setDisplayMobileMenu(payload));

  const getTitle = (type: PreferencesHeaderType) => {
    switch (type) {
      case 'notifications':
        return t('PREFERENCES.HEADER.TITLE.NOTIFICATIONS');
      case 'privacy':
        return t('PREFERENCES.HEADER.TITLE.PRIVACY');
      case 'personal':
        return t('PREFERENCES.HEADER.TITLE.PERSONAL');
      case 'devices':
        return t('PREFERENCES.HEADER.TITLE.DEVICES');
    }
  };
  const getDescription = (type: PreferencesHeaderType) => {
    switch (type) {
      case 'notifications':
        return t('PREFERENCES.HEADER.DESCRIPTION.NOTIFICATIONS');
      case 'privacy':
        return t('PREFERENCES.HEADER.DESCRIPTION.PRIVACY');
      case 'personal':
        return t('PREFERENCES.HEADER.DESCRIPTION.PERSONAL');
      case 'devices':
        return t('PREFERENCES.HEADER.DESCRIPTION.DEVICES');
    }
  };
  return (
    <BlockHead size="lg">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h4">{getTitle(type)}</BlockTitle>
          <BlockDes>
            <p>{getDescription(type)}</p>
          </BlockDes>
        </BlockHeadContent>
        <BlockHeadContent className="align-self-start d-lg-none">
          <Button
            className={clsx('toggle btn btn-icon btn-trigger mt-n1', {
              active: displayMobileMenu,
            })}
            onClick={() => changeDisplayMobileMenu(!displayMobileMenu)}
          >
            <Icon name="menu-alt-r"></Icon>
          </Button>
        </BlockHeadContent>
      </BlockBetween>
    </BlockHead>
  );
};
