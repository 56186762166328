import { useTranslation } from '@oproma/i18n';
import {
  emptyTrash,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Button } from '../button.component';
import { Col, Row } from '../grid.component';
import { useFileManagerTrash } from '../hooks';
import { Icon } from '../icon.component';
import { Select } from '../select.component';
import { FileManagerFiles } from './file-manager-files.component';

export const FileManagerTrash = () => {
  console.log('MyComponent re-rendered');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [dates, setDates] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: new Date(),
    to: new Date(),
  });
  const { members } = useAppSelector((state) => state.members);
  const { displayMobileRecoveryFilter } = useAppSelector(
    (state) => state.fileManager,
  );
  const trashEntities = useFileManagerTrash();
  const { deletedEntities } = useAppSelector((state) => state.workspace);
  const deletedUserIds = new Set(
    deletedEntities.map((deletion) => deletion.userId),
  );

  const [filteredTrashEntities, setFilteredTrashEntities] =
    useState(trashEntities);
  const [selectedMember, setSelectedMember] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const prevTrashEntities = useRef(trashEntities);

  // One-time initialization to set filteredTrashEntities
  useEffect(() => {
    setFilteredTrashEntities(trashEntities);
  }, []); // Empty array ensures this runs once on mount

  // Update only when trashEntities changes
  useEffect(() => {
    // Use a deep comparison to check if trashEntities has actually changed
    if (!isEqual(prevTrashEntities.current, trashEntities)) {
      setFilteredTrashEntities(trashEntities);
      prevTrashEntities.current = trashEntities; // Update the ref to the current value
    }
  }, [trashEntities]); // This effect runs every time trashEntities changes

  const memberOptions = members.reduce((options, member) => {
    if (member.id && deletedUserIds.has(member.id)) {
      const label = member.name || member.email;
      if (!label) {
        return options;
      }
      options.push({
        label,
        value: member.id,
      });
    }
    return options;
  }, [] as { label: string; value: string }[]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleEmptyingTrash = async () => {
    await dispatch(emptyTrash());
    toggle(); // Close the modal
  };

  const filterTrash = () => {
    let filtered = [...trashEntities];

    // Filter by dates if both are selected
    if (dates.from && dates.to) {
      const dateFrom = new Date(dates.from);
      const dateTo = new Date(dates.to);

      filtered = filtered.filter((entity) => {
        const timestamp = entity.modified!;
        const date = new Date(timestamp);
        return date >= dateFrom && date <= dateTo;
      });
    }

    if (selectedMember) {
      // get IDs of entities deleted by selected member
      const deletedEntityIds = deletedEntities
        .filter((deletion) => deletion.userId === selectedMember.value)
        .map((deletion) => deletion.entityId);

      // filter to entities with those ids
      filtered = filtered.filter((entity) =>
        deletedEntityIds.includes(entity.id),
      );
    }
    setFilteredTrashEntities(filtered);
  };

  const resetFilters = () => {
    setFilteredTrashEntities(trashEntities);
  };

  const handleSelectChange = (
    option: { value: string; label: string } | null,
  ) => {
    setSelectedMember(option);
  };

  return (
    <Row>
      <Col xl="3" className="order-xl-12">
        <div
          className={clsx('nk-fmg-filter toggle-expand-content', {
            expanded: displayMobileRecoveryFilter,
          })}
        >
          <Form>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Button
                    type="button"
                    color="danger"
                    size="md"
                    onClick={toggle}
                  >
                    {t('FILE_MANAGER.TRASH.EMPTY')}
                  </Button>
                </FormGroup>
              </Col>
              <Col lg="12" md="4">
                <FormGroup>
                  <label className="form-label">
                    {t('FILE_MANAGER.TRASH.FROM')}
                  </label>
                  <div className="form-control-wrap">
                    <DatePicker
                      selected={dates.from}
                      onChange={(date) => setDates({ ...dates, from: date })}
                      className="form-control date-picker"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12" md="4">
                <FormGroup>
                  <label className="form-label">
                    {t('FILE_MANAGER.TRASH.TO')}
                  </label>
                  <div className="form-control-wrap">
                    <DatePicker
                      selected={dates.to}
                      onChange={(date) => setDates({ ...dates, to: date })}
                      className="form-control date-picker"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12" md="4">
                <FormGroup>
                  <label className="form-label">
                    {t('FILE_MANAGER.TRASH.DELETED_BY')}
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={memberOptions}
                      placeholder={t('FILE_MANAGER.TRASH.DELETED_PLACEHOLDER')}
                      onChange={handleSelectChange}
                      value={selectedMember}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12">
                <div className="d-flex justify-between mt-1">
                  <button
                    type="reset"
                    className="link link-sm link-primary ms-n1"
                    onClick={resetFilters}
                  >
                    {t('FILE_MANAGER.TRASH.RESET_FILTER')}
                  </button>
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={filterTrash}
                  >
                    {t('FILE_MANAGER.TRASH.Filter')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col xl="9" lg="12">
        <FileManagerFiles
          entities={filteredTrashEntities}
          entityDisplayType="list"
          layoutType="trash"
        />
      </Col>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Confirm Empty Trash
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you would like to empty the trash of all of its
            contents?
          </p>
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button color="primary" onClick={handleEmptyingTrash}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};
