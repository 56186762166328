import { useTranslation } from '@oproma/i18n';
import {
  Workspace,
  setDisplayDeleteWorkspaceModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { Link } from '@oproma/router';
import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  UncontrolledDropdown,
} from 'reactstrap';
import { Icon } from '../icon.component';
import { Loader } from '../loader.component';
import { PreviewCard } from '../preview.component';
import { UserAvatar } from '../user-avatar.component';
import { findUpper, prettyBytes } from '../utils';
import { DeleteWorkspaceModal } from './modals';

type WorkspaceCardProps = {
  workspace: Workspace;
  workspaceQuery: string | undefined;
  onEdit: () => void;
  onDelete: () => void;
};

export const WorkspaceCard = ({
  workspace,
  workspaceQuery,
  onEdit,
  onDelete,
}: WorkspaceCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.user);
  const { fetchingDiskMetrics } = useAppSelector((state) => state.workspaces);
  // TODO: Refactor this code and move it to redux store
  const [isDeleting, setisDeleting] = useState(false);
  // TODO: Translation for these files

  return (
    <Col sm="6" lg="4" xxl="3" key={workspace.id}>
      <PreviewCard className="h-100">
        <div className="project">
          <div className="project-head">
            <Link
              to={`/${workspace.id}/file-manager`}
              className="project-title"
            >
              <UserAvatar
                className="sq"
                theme={'info'}
                text={findUpper(workspace.name)}
              />
              <div className="project-info">
                <h6 className="title">
                  <Highlighter
                    searchWords={[workspaceQuery || '']}
                    autoEscape={true}
                    textToHighlight={workspace.name ?? ''}
                  />
                </h6>
                <span className="sub-text">
                  {workspace.amOwner
                    ? t('COMMON.WORKSPACES.OWNER')
                    : t('COMMON.WORKSPACES.MEMBER')}
                </span>
              </div>
            </Link>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                disabled={isDeleting}
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEdit();
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>{t('COMMON.WORKSPACES.EDIT')}</span>
                    </DropdownItem>
                  </li>
                  {workspace.amOwner && (
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#markasdone"
                        onClick={(ev) => {
                          ev.preventDefault();
                          dispatch(setDisplayDeleteWorkspaceModal(true));
                        }}
                      >
                        <Icon name="trash"></Icon>
                        <span>{t('COMMON.WORKSPACES.DELETE')}</span>
                      </DropdownItem>
                    </li>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {isDeleting ? (
            <Loader />
          ) : (
            <>
              <div className="project-details">
                <ul className="team-statistics">
                  <li>
                    <span>
                      {fetchingDiskMetrics ? (
                        <Loader />
                      ) : (
                        workspace.fileCount ?? t('COMMON.NONE')
                      )}
                    </span>
                    <span>{t('COMMON.WORKSPACES.FILE_COUNT')}</span>
                  </li>
                  <li>
                    <span>
                      {fetchingDiskMetrics ? (
                        <Loader />
                      ) : (
                        workspace.folderCount ?? t('COMMON.NONE')
                      )}
                    </span>
                    <span>{t('COMMON.WORKSPACES.FOLDER_COUNT')}</span>
                  </li>
                  <li>
                    <span>{workspace.membersLimit}</span>
                    <span>{t('COMMON.WORKSPACES.MEMBERS_LIMIT')}</span>
                  </li>
                </ul>
              </div>
              <div className="project-progress">
                <div className="project-progress-details">
                  <div className="project-progress-task">
                    <Icon name="clip"></Icon>
                    <span>{t('COMMON.STORAGE')}</span>
                  </div>
                  <div className="project-progress-percent">
                    {prettyBytes(workspace.usage ?? 0)}/
                    {prettyBytes(workspace.bytesLimit ?? 0)}
                  </div>
                </div>
                <Progress
                  className="progress-pill progress-md bg-light"
                  value={
                    ((workspace.usage ?? 1) / (workspace.bytesLimit ?? 1)) * 100
                  }
                ></Progress>
              </div>
              <div className="project-meta">
                <ul className="project-users g-1">
                  {/* {workspace..slice(0, 2).map((item, idx) => {
                        return (
                          <li key={idx}>
                            <UserAvatar
                              className="sm"
                              text={findUpper(item.label)}
                              theme={item.theme}
                              image={item.image}
                            />
                          </li>
                        );
                      })} */}
                  <li>
                    <UserAvatar
                      className="sm"
                      text={findUpper(name)}
                      theme={'info'}
                      image={undefined}
                    />
                  </li>
                  <li>
                    <UserAvatar
                      className="sm"
                      theme="light"
                      text={`+${(workspace.membersLimit ?? 2) - 1}`}
                    />
                  </li>
                  {/* {item.team.length > 2 && (
                        <li>
                          <UserAvatar
                            theme="light"
                            className="sm"
                            text={`+${10 - 2}`}
                          />
                        </li>
                      )} */}
                </ul>
                <Badge
                  className="badge-dim"
                  color={workspace.enabled ? 'success' : 'danger'}
                >
                  <Icon
                    name={workspace.enabled ? 'check-circle' : 'cross-circle'}
                  ></Icon>
                  <span>{workspace.enabled ? 'Enabled' : 'Disabled'}</span>
                </Badge>
              </div>
            </>
          )}
        </div>
        <DeleteWorkspaceModal workspaceId={workspace.id} />
      </PreviewCard>
    </Col>
  );
};
