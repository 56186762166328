import { Button } from '../button.component';
import { Icon } from '../icon.component';
import {
  setDashboardPaginatedPage,
  setDashboardSortOrder,
  setDisplayDashboardFinder,
  setDisplayMobileDashboardTable,
  setDisplayMobileMembersTable,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { DashboardTablePaginator } from './dashboard-table-paginator.component';
import clsx from 'clsx';
const MobileDashboardActivityTableToggle = () => {
  const dispatch = useAppDispatch();

  const {
    displayDashboardFinder,
    displayMobileDashboardTable,
    dashboardSortOrder,
  } = useAppSelector((state) => state.dashboard);

  const toggleDashboardFinder = () => {
    dispatch(setDisplayDashboardFinder(!displayDashboardFinder));
  };
  const toggleMobileMembersTable = () => {
    dispatch(setDisplayMobileMembersTable(!displayMobileDashboardTable));
  };

  const handleClick = () => {
    const newSortOrder = dashboardSortOrder === 'asc' ? 'desc' : 'asc';
    dispatch(setDashboardPaginatedPage(1));
    dispatch(setDashboardSortOrder(newSortOrder));
  };

  return (
    <div className="card-tools me-n1">
      <ul className="btn-toolbar gx-1">
        <li>
          <a
            href="#search"
            onClick={(ev) => {
              ev.preventDefault();
              toggleDashboardFinder();
            }}
            className="btn btn-icon search-toggle toggle-search"
          >
            <Icon name="search"></Icon>
          </a>
        </li>
        <li className="btn-toolbar-sep"></li>
        <li>
          <div className="toggle-wrap">
            <Button
              className={clsx('btn-icon btn-trigger toggle', {
                active: setDisplayMobileDashboardTable,
              })}
              onClick={toggleMobileMembersTable}
            >
              <Icon name="menu-right"></Icon>
            </Button>
            <div
              className={clsx('toggle-content', {
                'content-active': displayMobileDashboardTable,
              })}
            >
              <ul className="btn-toolbar gx-1">
                <li className="toggle-close">
                  <Button
                    className="btn-icon btn-trigger toggle"
                    onClick={toggleMobileMembersTable}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn btn-trigger btn-icon"
                    onClick={handleClick}
                  >
                    <div></div>
                    <Icon name="sort-v"></Icon>
                  </Button>
                </li>
                <li>
                  <DashboardTablePaginator />
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MobileDashboardActivityTableToggle;
