import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import { UploadLevelEnum } from '@oproma/prividox-orchestration-open-api';
import {
  FileWithPath,
  createFile,
  getWorkspaceEntities,
  setQueuedFiles,
  toggleDisplayCreateFileModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { InputGroup, InputGroupText, Modal, ModalBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { securityLevels } from '../constants';
import { PublisableFileEntry } from './publishable-file-entry.component';

const securityLevelSchema = yup
  .object({
    securityLevel: yup.string().default('NONE'),
  })
  .required();

type SecurityLevelFormValues = yup.InferType<typeof securityLevelSchema>;

export const FileManagerCreateFileModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessLevel } = useAppSelector((state) => state.workspace);
  const { workspaceId, entityId } = useParams();
  const { displayCreateFileModal, queuedFiles } = useAppSelector(
    (state) => state.fileManager,
  );
  const openedWorkspace = useAppSelector((state) =>
    state.workspaces.workspaces.find(
      (workspace) => workspace.id === workspaceId,
    ),
  );
  const [publishableUids, setPublishableUids] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false); // Track if files are currently being uploaded
  const abortControllersRef = useRef<{ [key: string]: AbortController }>({});

  const modalContentRef = useRef<HTMLDivElement>(null); // Ref for the modal content

  const { watch, setValue, reset } = useForm<SecurityLevelFormValues>({
    resolver: yupResolver(securityLevelSchema),
    defaultValues: {
      securityLevel: 'NONE',
    },
  });

  const toggle = () => {
    dispatch(toggleDisplayCreateFileModal());
  };

  const changeableSecurityLevel = watch('securityLevel');

  const closeModal = () => {
    // Close the modal
    toggle();
    // Reset the state
    dispatch(setQueuedFiles([]));
    setPublishableUids([]);
    dispatch(
      getWorkspaceEntities((entityId as string) ?? openedWorkspace?.root),
    );
  };

  const handleDropChange = (droppedFiles: File[]) => {
    const newFiles = droppedFiles.filter(
      (droppedFile) =>
        !queuedFiles.some((file) => file.name === droppedFile.name),
    ) as FileWithPath[];

    dispatch(setQueuedFiles([...queuedFiles, ...newFiles]));
  };

  const cancelUpload = (processUid: string) => {
    if (abortControllersRef.current[processUid]) {
      abortControllersRef.current[processUid].abort();
      delete abortControllersRef.current[processUid];
    }
  };

  const translatedSecurityLevels = securityLevels.map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  const persistFiles = async () => {
    const publishableUids = queuedFiles.map((file) => {
      const publishUid = uuidv4();
      const target = entityId || openedWorkspace?.root;
      if (!target) {
        return undefined;
      }
      const abortController = new AbortController();
      abortControllersRef.current[publishUid] = abortController;
      dispatch(
        createFile({
          processUid: publishUid,
          file,
          metadata: {
            target,
            count: 1, // one chunk
            encrypted: false,
            filesize: file.size,
            index: 0, // first chunk
            name: file.name,
            original: target,
            upload: publishUid,
            body: {},
            level: changeableSecurityLevel as UploadLevelEnum,
          },
          signal: abortController.signal,
          t,
        }),
      );
      return publishUid;
    });
    const filteredPublishableUids = publishableUids.filter(
      (uid): uid is string => uid !== undefined,
    );
    setPublishableUids(filteredPublishableUids);
  };

  const removeFromList = (name: string, index: number) => {
    let defaultFiles = queuedFiles;
    defaultFiles = defaultFiles.filter((item) => item.name !== name);
    dispatch(setQueuedFiles(defaultFiles));

    // Also remove the corresponding uploadId
    const defaultUploadIds = [...publishableUids];
    defaultUploadIds.splice(index, 1);
    setPublishableUids(defaultUploadIds);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(target)
      ) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Modal
      {...{
        isOpen: displayCreateFileModal,
        toggle,
        size: 'xl',
        backdrop: false,
        fade: true,
        zIndex: 3,
      }}
      className="nk-modal-file-manager"
    >
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          //closeModal();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div ref={modalContentRef} className="modal-content">
        <ModalBody className="modal-body-md">
          <div className="nk-upload-form">
            <h5 className="title mb-3">
              {t('FILE_MANAGER.UPLOAD_MODAL.TITLE')}
            </h5>
            {openedWorkspace?.access === 'MIXED' &&
              (accessLevel?.toString() === '127' ||
                accessLevel?.toString() === '126' ||
                accessLevel?.toString() === '0') && (
                <div className="nk-file-details-header d-flex justify-content-between">
                  <div className="nk-file-details-input nk-file-details-input-to">
                    <InputGroup>
                      <InputGroupText>
                        {t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.TITLE')}
                      </InputGroupText>
                      <Select
                        options={translatedSecurityLevels}
                        className="input-mail w-200px"
                        placeholder={translatedSecurityLevels[0].label}
                        closeMenuOnSelect={true}
                        isSearchable={false}
                        onChange={(ev) =>
                          ev?.value && setValue('securityLevel', ev.value)
                        }
                      ></Select>
                    </InputGroup>
                  </div>
                </div>
              )}
            <Dropzone onDrop={handleDropChange}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    className="dropzone upload-zone small bg-lighter dz-clickable my-2"
                  >
                    <input {...getInputProps()} />
                    <div className="dz-message">
                      <span
                        className="dz-message-text"
                        dangerouslySetInnerHTML={{
                          __html: t('FILE_MANAGER.UPLOAD_MODAL.PLACEHOLDER'),
                        }}
                      ></span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="nk-upload-list">
            <h5 className="title">{t('FILE_MANAGER.UPLOAD_MODAL.HEADING')}</h5>

            {queuedFiles.length > 0 ? (
              <SimpleBar style={{ maxHeight: '100px' }}>
                {queuedFiles.map((file, index) => (
                  <PublisableFileEntry
                    key={index}
                    file={file}
                    index={index}
                    processUid={publishableUids[index]}
                    removeFromList={removeFromList}
                    cancelUpload={cancelUpload}
                  />
                ))}
              </SimpleBar>
            ) : (
              <div className="d-flex justify-center">
                <p className="text-soft">
                  {t('FILE_MANAGER.UPLOAD_MODAL.NO_FILES')}
                </p>
              </div>
            )}
          </div>
          <div className="nk-modal-action justify-end">
            <ul className="btn-toolbar g-4 align-center">
              <li>
                <Button
                  color="primary"
                  onClick={persistFiles}
                  disabled={uploading}
                >
                  {t('FILE_MANAGER.UPLOAD_MODAL.UPLOAD_BUTTON')}
                </Button>
              </li>
            </ul>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
