import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from '@oproma/i18n';
import {
  editWorkspaceMember,
  resetPassword,
  setDisplayEditMemberModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form, Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';
import { Button } from '../../button.component';
import { Col } from '../../grid.component';
import { Icon } from '../../icon.component';
import { Select } from '../../select.component';
import { permissionLevels } from '../constants';

const editMemberSchema = yup
  .object({
    permission: yup.string().required(),
  })
  .required();

type EditMemberFormValues = yup.InferType<typeof editMemberSchema>;

export const EditMemberModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { workspaceId } = useParams();
  const { displayEditMemberModal } = useAppSelector((state) => state.members);
  const lastOpenedMember = useAppSelector((state) =>
    state.members.members.find(
      (member) => member.id === state.members.lastOpenedMember,
    ),
  );

  // Translate permission levels
  const translatedPermissionLevels = permissionLevels.map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EditMemberFormValues>({
    resolver: yupResolver(editMemberSchema),
    defaultValues: {
      permission: '-127',
    },
  });

  const onFormSubmit: SubmitHandler<EditMemberFormValues> = ({
    permission,
  }) => {
    if (!workspaceId || !lastOpenedMember?.id) {
      return;
    }
    dispatch(
      editWorkspaceMember({
        workspaceId: workspaceId,
        user: lastOpenedMember?.id,
        accessLevelSpec: {
          access: permission,
        },
      }),
    );
    toggleModal();
  };

  const toggleModal = () => {
    dispatch(setDisplayEditMemberModal(!displayEditMemberModal));
    reset();
  };

  const findPermissionLevelForMember = () => {
    return translatedPermissionLevels.find(
      (permissionLevel) =>
        permissionLevel.value === lastOpenedMember?.accessLevel?.toString(),
    );
  };

  const handleResetPassword = () => {
    if (lastOpenedMember?.active && lastOpenedMember.email) {
      dispatch(
        resetPassword({
          email: lastOpenedMember.email,
        }),
      );
    } else {
      toast.error('Cannot reset password for non registered member');
    }
  };

  return (
    <Modal
      isOpen={displayEditMemberModal}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggleModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{t('MEMBERS.EDIT_MEMBER.TITLE')}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">
                    {t('MEMBERS.EDIT_MEMBER.PERMISSION')}
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={translatedPermissionLevels}
                      defaultValue={findPermissionLevelForMember()}
                      onChange={(ev) =>
                        setValue(
                          'permission',
                          ev?.value ?? translatedPermissionLevels[0].value,
                        )
                      }
                    />
                    {errors.permission && (
                      <span className="text-danger">
                        {errors.permission.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                  <li className="me-4">
                    <Button color="primary" size="md" type="submit">
                      {t('MEMBERS.EDIT_MEMBER.CONFIRM_BUTTON')}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#trigger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleResetPassword();
                      }}
                      className="link link-light"
                    >
                      {t('MEMBERS.EDIT_MEMBER.RESET_EMAIL')}
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
