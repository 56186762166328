import {
  GetPermissionsRequest,
  GetUserPermissionsRequest,
  SetPermissionRequest,
} from '@oproma/prividox-orchestration-open-api';
import { permissionsApi } from './config';

const fetchMemberPermission = async (payload: GetUserPermissionsRequest) => {
  const response = await permissionsApi.getUserPermissions(payload);
  return response;
};

const fetchGroupPermission = async (payload: GetUserPermissionsRequest) => {
  const response = await permissionsApi.getUserPermissions(payload);
  return response;
};

const patchMemberPermission = async (payload: SetPermissionRequest) => {
  const response = await permissionsApi.setPermission(payload);
  return response;
};

const fetchPermissions = (payload: GetPermissionsRequest) => {
  return permissionsApi.getPermissions(payload);
};

export const permissionsService = {
  fetchPermissions,
  fetchGroupPermission,
  fetchMemberPermission,
  patchMemberPermission,
};
