import {
  BundleSpecTypeEnum,
  PriceSpec,
} from '@oproma/prividox-orchestration-open-api';
import {
  editWorkspaceBundle,
  toggleDisplayBundlesModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Button } from '../../button.component';
import { Col, Row } from '../../grid.component';
import { InputNumber } from '../../input-number.component';
import { currencyFormatter } from '../../utils';

export enum BundlePackageTypeEnum {
  users = 'addon-users',
  space = 'addon-space',
}

export const BundlesModal = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(0);
  const { loading, displayBundlesModal, bundles, metadata } = useAppSelector(
    (state) => state.workspace,
  );

  const [memberCount, setMemberCount] = useState(
    bundles.find(
      (bundle) =>
        bundle.meta?.['package-i18n-name'] === BundlePackageTypeEnum.users,
    )?.count ?? 1,
  );
  const [storageCount, setStorageCount] = useState(
    bundles.find(
      (bundle) =>
        bundle.meta?.['package-i18n-name'] === BundlePackageTypeEnum.space,
    )?.count ?? 20,
  );

  useEffect(() => {
    const storage = bundles.find(
      (bundle) =>
        bundle.meta?.['package-i18n-name'] === BundlePackageTypeEnum.space,
    );

    const member = bundles.find(
      (bundle) =>
        bundle.meta?.['package-i18n-name'] === BundlePackageTypeEnum.users,
    );

    // Precondition: If storage and member are defined then count can never be undefined
    if (storage) {
      setStorageCount(storage.count!);
    }

    if (member) {
      setMemberCount(member.count!);
    }
  }, [bundles]);

  // TODO: Move to i18n files. This has to be changed
  const getBundleText = (
    text: string | undefined,
    amount?: string,
    currency?: string,
  ) => {
    switch (text) {
      case 'addon-space':
        return 'Storage (GB)';
      case 'addon-space-desc':
        return `Grants an extra 1 GiB of storage to the workspace for $${amount} ${currency} plus case applicable taxes billed monthly.`;
      case 'addon-users':
        return 'Members';
      case 'addon-users-desc':
        return `Grants extra member capacity to the workspace for $${amount} ${currency} plus applicable taxes billed monthly.`;
      default:
        return 'Case not found.';
    }
  };

  const toggle = () => {
    dispatch(toggleDisplayBundlesModal());
  };

  const handleStorageCountChange = () => {
    if (!metadata?.id) {
      return;
    }
    dispatch(
      editWorkspaceBundle({
        workspaceId: metadata.id,
        bundleSpec: {
          type: BundleSpecTypeEnum.space,
          count: storageCount,
        },
      }),
    );
  };

  const handleMemberCountChange = () => {
    if (!metadata?.id) {
      return;
    }
    dispatch(
      editWorkspaceBundle({
        workspaceId: metadata.id,
        bundleSpec: {
          type: BundleSpecTypeEnum.users,
          count: memberCount,
        },
      }),
    );
  };

  const calculateBundlePrice = (bundle: PriceSpec) => {
    return (
      ((bundle.amount ?? 100) / 100) *
      (bundle.meta?.['package-i18n-name'] === BundlePackageTypeEnum.space
        ? storageCount
        : memberCount)
    );
  };

  return (
    <Modal isOpen={displayBundlesModal} size="lg" toggle={toggle}>
      <a
        href="/"
        className="close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
      >
        <em className="icon ni ni-cross"></em>
      </a>
      <ModalBody className="modal-body-md">
        <div className="p-2">
          <h5 className="title">Bundles</h5>
          <ul className="nk-nav nav nav-tabs">
            {bundles.map((bundle, index) => (
              <li className="nav-item" key={bundle.id}>
                <a
                  className={clsx(`nav-link`, { active: tab === index })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setTab(index);
                  }}
                  href={`#${bundle.meta?.['package-i18n-name']}`}
                >
                  {bundle.meta?.['package-i18n-name'] ===
                  BundlePackageTypeEnum.space
                    ? 'More Storage'
                    : 'More Members'}
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-content">
            {bundles.map((bundle, index) => (
              <div
                className={clsx(`tab-pane`, { active: tab === index })}
                id={`#${bundle.meta?.['package-i18n-name']}`}
                key={bundle.id}
              >
                {/* {(error?.code === UserErrorCodesEnum.EDIT_NAME_FAILED ||
                error?.code === UserErrorCodesEnum.EDIT_USERNAME_FAILED) && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    <Icon name="alert-circle" /> {error.message}
                  </Alert>
                </div>
              )} */}
                {/* {displayConfirmedStorageCount && (
                  <Alert className="alert-icon" color="success">
                    <Icon name="check-circle" />
                    <strong>Completed</strong> - Storage has been changed!
                  </Alert>
                )} */}
                <Form
                // onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Row className="gy-4">
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="full-name">
                          {getBundleText(bundle.meta?.['package-i18n-name'])}
                        </label>
                        <p>
                          {getBundleText(
                            bundle.meta?.['package-i18n-desc'],
                            currencyFormatter(bundle.amount ?? -1),
                            bundle.currency?.toUpperCase(),
                          )}
                        </p>
                        <div className="form-control-wrap">
                          <InputNumber
                            start={
                              bundle.meta?.['package-i18n-name'] ===
                              BundlePackageTypeEnum.space
                                ? storageCount
                                : memberCount
                            }
                            color="primary"
                            setNumber={(number) => {
                              bundle.meta?.['package-i18n-name'] ===
                              BundlePackageTypeEnum.space
                                ? setStorageCount(number)
                                : setMemberCount(number);
                            }}
                            min={bundle.count}
                            disabled={!bundle.enabled}
                            // max={100}
                          />
                          {/* {errors.name && (
                          <span className="invalid">{errors.name.message}</span>
                        )} */}
                          <p>
                            Cost is ${calculateBundlePrice(bundle)}{' '}
                            {bundle.currency?.toUpperCase()} plus tax
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                        <li>
                          <Button
                            color="primary"
                            size="md"
                            // type="submit"
                            onClick={(ev) => {
                              ev.preventDefault();
                              bundle.meta?.['package-i18n-name'] ===
                              BundlePackageTypeEnum.space
                                ? handleStorageCountChange()
                                : handleMemberCountChange();
                            }}
                          >
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : bundle.meta?.['package-i18n-name'] ===
                              BundlePackageTypeEnum.space ? (
                              'Confirm Extra Storage'
                            ) : (
                              'Confirm Extra Members'
                            )}
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Form>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
