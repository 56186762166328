import {
  SubscribeRequest,
  UnsubscribeRequest,
  UpdateNameSpec,
  UpdatePasswordSpec,
} from '@oproma/prividox-orchestration-open-api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  extractApiError,
  filesService,
  metadataService,
  notificationsService,
  userService,
} from '../api';
import { getChildEntities } from '../file-manager';
import { AppState } from '../store';
import { IUserState, UserErrorCodesEnum } from './types';

const initialState: IUserState = {
  id: null,
  name: null,
  'app.settings.collapseNavbar': null,
  'app.settings.theme': null,
  'app.settings.timeFormat': 'HH:mm',
  'app.settings.dateFormat': 'YYYY/MM/DD',
  'releaseNotes.versionCollapsed': null,
  validMFACode: null,
  presence: null,
  address: null,
  phone: null,
  subscribed: false,
  tagline: null,
  type: null,
  changedDateFormat: false,
  changedTimeFormat: false,
  changedName: false,
  changedPassword: false,
  changedPhone: false,
  changedLocation: false,
  changedTagline: false,
  changedSystemLanguage: false,
  changedEmailNotificationProvider: false,
  enhancedSecurity: false,
  sessions: {},
  mfa: null,
  email: null,
  language: null,
  loading: false,
  error: null,
};

export const getUserMetadata = createAsyncThunk(
  '@@user/getUserMetadata',
  async (userId: string | null, { rejectWithValue }) => {
    try {
      if (!userId) return rejectWithValue('No userId provided');
      const response = await userService.fetchUser(userId);
      return response;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getUserSessions = createAsyncThunk(
  '@@userSessions/getUserSessions',
  async () => {
    return await userService.fetchUserSessions();
  },
);

export const editNameOfUser = createAsyncThunk(
  '@@user/editNameOfUser',
  async (payload: UpdateNameSpec, { rejectWithValue }) => {
    try {
      if (!payload.name) return rejectWithValue('No name provided');
      await userService.patchNameOfUser(payload);
      return payload.name;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editPassword = createAsyncThunk(
  '@@user/editPassword',
  async (payload: UpdatePasswordSpec, { rejectWithValue }) => {
    try {
      if (!payload.old) return rejectWithValue('No old password provided');
      if (!payload.pass) return rejectWithValue('No new password provided');
      await userService.patchUserPassword({
        old: payload.old,
        pass: payload.pass,
      });
      return payload.pass;
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const userEntitySubscribe = createAsyncThunk(
  '@@user/userEntitySubscribe',
  async (payload: SubscribeRequest, { rejectWithValue, dispatch }) => {
    try {
      if (!payload.body) return rejectWithValue('No body provided');
      await userService.patchUserSubscription(payload.body);
      const parentEntity = await filesService.fetchEntityParent(payload.body);

      dispatch(
        getChildEntities({
          entityId: parentEntity,
        }),
      );
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

export const entityUnsubscribe = createAsyncThunk(
  '@@user/entityUnsubscribe',
  async (payload: UnsubscribeRequest, { rejectWithValue, dispatch }) => {
    try {
      if (!payload.subscribed) return rejectWithValue('Could not unsubscribe');
      await userService.removeSubscription(payload.subscribed);
      const parentEntity = await filesService.fetchEntityParent(
        payload.subscribed,
      );

      dispatch(
        getChildEntities({
          entityId: parentEntity,
        }),
      );
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

export const editTagline = createAsyncThunk(
  '@@user/editTagline',
  async (tagline: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      const response = await metadataService.patchTagline(uid, tagline);
      return {
        status: response,
        tagline,
      };
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editLocation = createAsyncThunk(
  '@@user/editLocation',
  async (address: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      const response = await metadataService.patchUserAddress(uid, address);
      return {
        status: response,
        address,
      };
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editPhone = createAsyncThunk(
  '@@user/editPhone',
  async (phone: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      const response = await metadataService.patchUserPhoneNumber(uid, phone);
      return {
        status: response,
        phone,
      };
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editSystemTime = createAsyncThunk(
  '@@user/editSystemTime',
  async (time: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      return await metadataService.patchSystemTime(uid, time);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editEmailNotificationProvider = createAsyncThunk(
  '@@user/editEmailNotificationProvider',
  async (payload: boolean, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      return await notificationsService.patchEmailNotificationProvider(payload);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editSystemLanguage = createAsyncThunk(
  '@@user/editSystemLanguage',
  async (payload: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      return await metadataService.patchSystemLanguage(uid, payload);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editSystemDate = createAsyncThunk(
  '@@user/editSystemDate',
  async (date: string, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      await metadataService.patchSystemDate(uid, date);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getUserPresenceStatus = createAsyncThunk(
  '@@user/getUserPresenceStatus',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { uid } = (getState() as AppState).auth;
      if (!uid) return rejectWithValue('No userId provided');
      return await userService.fetchUserPresenceStatus(uid);
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getMFABackupCodes = createAsyncThunk(
  '@@user/getMFABackupCodes',
  async (_, { rejectWithValue }) => {
    try {
      return await userService.fetchMFABackupCodes();
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

export const validateMFACode = createAsyncThunk(
  '@@user/validateMFACode',
  async (code: number, { rejectWithValue }) => {
    try {
      return await userService.validaMFACode(code);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
export const requireMFA = createAsyncThunk(
  '@@user/requireMFA',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      await userService.requireMFA();
      const uid = (getState() as AppState).auth.uid;
      dispatch(getUserMetadata(uid));
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    resetTimeFormatChangeFlag(state) {
      state.changedTimeFormat = false;
    },
    resetChangedFields(state) {
      state.changedName = false;
      state.changedTagline = false;
      state.changedLocation = false;
      state.changedPhone = false;
      state.changedPassword = false;
    },
    setValidMFACode: (state, action: PayloadAction<boolean | null>) => {
      state.validMFACode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserMetadata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.GET_USER_METADATA_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getUserMetadata.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
        state.enhancedSecurity = action.payload.enhancedSecurity;
        // state.email = action.payload.email;
        // state.name = action.payload.name;
        // state.username = action.payload.username;
        // state.language = action.payload.language;
        // state.id = action.payload.id;
        state.loading = false;
      });
    builder
      .addCase(editEmailNotificationProvider.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editEmailNotificationProvider.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_EMAIL_NOTIFICATION_PROVIDER_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editEmailNotificationProvider.fulfilled, (state, action) => {
        state.changedEmailNotificationProvider = action.payload;
        state.loading = false;
      });
    builder
      .addCase(editSystemLanguage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editSystemLanguage.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_SYSTEM_LANGUAGE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editSystemLanguage.fulfilled, (state, action) => {
        state.changedSystemLanguage = action.payload;
        state.loading = false;
      });
    builder
      .addCase(getUserSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.GET_USER_SESSIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getUserSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
        state.error = null;
      });
    builder
      .addCase(getUserPresenceStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserPresenceStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.GET_USER_SESSIONS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getUserPresenceStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.presence = action.payload;
        state.error = null;
      });
    builder
      .addCase(editNameOfUser.pending, (state) => {
        state.loading = true;
        state.changedName = false;
        state.error = null;
      })
      .addCase(editNameOfUser.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_NAME_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editNameOfUser.fulfilled, (state, action) => {
        state.loading = false;
        state.name = action.payload;
        state.changedName = true;
      });
    builder
      .addCase(editPassword.pending, (state) => {
        state.loading = true;
        state.changedPassword = false;
        state.error = null;
      })
      .addCase(editPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_PASSWORD_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.changedPassword = true;
      });
    builder
      .addCase(editTagline.pending, (state) => {
        state.loading = true;
        state.changedTagline = false;
        state.error = null;
      })
      .addCase(editTagline.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_TAGLINE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editTagline.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status) {
          state.changedTagline = true;
          state.tagline = action.payload.tagline;
          state.error = null;
        } else {
          state.error = {
            code: UserErrorCodesEnum.EDIT_TAGLINE_FAILED,
            message: 'Failed to edit tagline. Please try again.',
          };
        }
      });
    builder
      .addCase(editLocation.pending, (state) => {
        state.loading = true;
        state.changedLocation = false;
        state.error = null;
      })
      .addCase(editLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_LOCATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editLocation.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status) {
          state.changedLocation = true;
          state.address = action.payload.address;
          state.error = null;
        } else {
          state.error = {
            code: UserErrorCodesEnum.EDIT_LOCATION_FAILED,
            message: 'Failed to edit address. Please try again.',
          };
        }
      });
    builder
      .addCase(editPhone.pending, (state) => {
        state.loading = true;
        state.changedPhone = false;
        state.error = null;
      })
      .addCase(editPhone.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_PHONE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editPhone.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status) {
          state.changedPhone = true;
          state.phone = action.payload.phone;
          state.error = null;
        } else {
          state.error = {
            code: UserErrorCodesEnum.EDIT_PHONE_FAILED,
            message: 'Failed to edit phone number. Please try again.',
          };
        }
      });
    builder
      .addCase(editSystemTime.pending, (state) => {
        state.loading = true;
        state.changedTimeFormat = false;
        state.error = null;
      })
      .addCase(editSystemTime.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_TIME_FORMAT_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editSystemTime.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.changedTimeFormat = true;
          state.error = null;
        } else {
          state.error = {
            code: UserErrorCodesEnum.EDIT_TIME_FORMAT_FAILED,
            message: 'Failed to edit time format. Please try again.',
          };
        }
      });
    builder
      .addCase(editSystemDate.pending, (state) => {
        state.loading = true;
        state.changedDateFormat = false;
        state.error = null;
      })
      .addCase(editSystemDate.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.EDIT_DATE_FORMAT_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(editSystemDate.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.changedDateFormat = true;
          state.error = null;
        } else {
          state.error = {
            code: UserErrorCodesEnum.EDIT_DATE_FORMAT_FAILED,
            message: 'Failed to edit date format. Please try again.',
          };
        }
      });
    builder
      .addCase(userEntitySubscribe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userEntitySubscribe.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.USER_SUBSCRIPTION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(userEntitySubscribe.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(entityUnsubscribe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(entityUnsubscribe.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.USER_UNSUBSCRIBE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(entityUnsubscribe.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      });
    builder
      .addCase(getMFABackupCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMFABackupCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.GET_MFA_BACKUP_CODES_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(getMFABackupCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.mfa = action.payload;
      });
    builder
      .addCase(validateMFACode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateMFACode.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: UserErrorCodesEnum.VALIDATE_MFA_CODE_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(validateMFACode.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.validMFACode = action.payload;
      });
  },
});

export const { setValidMFACode } = userSlice.actions;

export const { resetTimeFormatChangeFlag, resetChangedFields } =
  userSlice.actions;
export const userReducer = userSlice.reducer;
