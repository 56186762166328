import { useTranslation } from '@oproma/i18n';
import { WorkspaceMember, useAppSelector } from '@oproma/prividox-store';
import { Link, useParams } from '@oproma/router';
import clsx from 'clsx';
import { DataTableItem, DataTableRow } from '../data-table.component';
import { Icon } from '../icon.component';
import { Tooltip } from '../tooltip.component';
import { UserAvatar } from '../user-avatar.component';
import { findUpper, generateGravatarUrl } from '../utils';
import { getMemberPermissionLevelLabel } from './constants';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useMemberRow } from '../hooks/use-member-row.hook';

export const MemberRow: React.FC<{
  memberEntry: WorkspaceMember;
}> = ({ memberEntry }) => {
  const { workspaceId } = useParams();
  const { id: userId } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const { memberId, onEditMember, onDeleteMember, onCheckMember } =
    useMemberRow(memberEntry);

  // Render logic for each member row
  return (
    <DataTableItem key={memberId}>
      <DataTableRow className="nk-tb-col-check">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={memberEntry.checked}
            id={memberId}
            onChange={(ev) => onCheckMember(ev)}
          />
          <label className="custom-control-label" htmlFor={memberId} />
        </div>
      </DataTableRow>
      <DataTableRow>
        <Link
          to={
            memberEntry?.active
              ? `/${workspaceId}/members/${memberEntry.id}`
              : '#'
          }
        >
          <div className="user-card">
            {memberEntry.thumbnail ? (
              <img
                src={generateGravatarUrl(memberEntry.email!)}
                alt={memberEntry.name}
                className="user-avatar rounded-circle mr-2"
              ></img>
            ) : (
              <UserAvatar
                //  + URLEncoder.encode(VariableName, "UTF-8")
                theme={memberEntry.thumbnailBackground}
                text={findUpper(memberEntry.name)}
                image={memberEntry.thumbnail}
              ></UserAvatar>
            )}
            <div className="user-info">
              <span className="tb-lead">
                {memberEntry.name}{' '}
                <span
                  className={clsx(
                    'dot',
                    {
                      'dot-success': memberEntry.online,
                      'dot-danger': !memberEntry.online,
                    },
                    'd-md-none ms-1',
                  )}
                ></span>
              </span>
              <span>{memberEntry.email}</span>
            </div>
          </div>
        </Link>
      </DataTableRow>
      <DataTableRow size="mb">
        <span className="tb-amount">
          {t(getMemberPermissionLevelLabel(`${memberEntry.accessLevel}`))}
        </span>
      </DataTableRow>
      <DataTableRow size="mb">
        <ul className="list-status">
          <li>
            <Icon
              className={clsx({
                'text-success': memberEntry.active && memberEntry.online,
                'text-info': memberEntry.active,
                'text-secondary': !memberEntry.online,
              })}
              name={
                memberEntry.active && memberEntry.online
                  ? 'check-circle'
                  : memberEntry.active && !memberEntry.online
                  ? 'alert-circle'
                  : 'alarm-alt'
              }
            ></Icon>{' '}
            <span>
              {memberEntry.active ? 'Registered' : 'Pending Registration'}
            </span>
          </li>
        </ul>
      </DataTableRow>
      <DataTableRow size="md">
        <span
          className={clsx('tb-status', {
            'text-success': memberEntry.online,
            'text-danger': !memberEntry.online,
          })}
        >
          {memberEntry.online ? 'Online' : 'Offline'}
        </span>
      </DataTableRow>
      <DataTableRow className="nk-tb-col-tools">
        {memberEntry.id !== userId && (
          <ul className="nk-tb-actions gx-1">
            <li
              className="nk-tb-action-hidden"
              onClick={() => onEditMember(memberId ?? null)}
            >
              <Tooltip
                tag="a"
                containerClassName="btn btn-trigger btn-icon"
                id={'edit_' + memberId}
                icon="edit-alt-fill"
                direction="top"
                text="Edit"
              />
            </li>
            <li
              className="nk-tb-action-hidden"
              onClick={() => onDeleteMember(memberId ?? null)}
            >
              <Tooltip
                tag="a"
                containerClassName="btn btn-trigger btn-icon"
                id={'remove_' + memberId}
                icon="user-cross-fill"
                direction="top"
                text="Remove"
              />
            </li>

            <li>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="a"
                  className="dropdown-toggle btn btn-icon btn-trigger"
                >
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    <li onClick={() => onEditMember(memberId ?? null)}>
                      <DropdownItem
                        tag="a"
                        href="#edit"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="edit"></Icon>
                        <span>{t('MEMBERS.ROW_OPTIONS.EDIT')}</span>
                      </DropdownItem>
                    </li>

                    <li className="divider"></li>
                    <li onClick={() => onDeleteMember(memberId ?? null)}>
                      <DropdownItem
                        tag="a"
                        href="#remove"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="na"></Icon>
                        <span>{t('MEMBERS.ROW_OPTIONS.REMOVE')}</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        )}
      </DataTableRow>
    </DataTableItem>
  );
};
