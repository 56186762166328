import {
  Block,
  Button,
  Col,
  Icon,
  Layout,
  PreferencesHeader,
  PreferencesLayout,
  Row,
  Select,
  retrieveApiErrorMessage,
  usePersonalPreferencesForm,
} from '@oproma/core-components';
import {
  LanguageEnum,
  UserErrorCodesEnum,
  resetTimeFormatChangeFlag,
  useAppDispatch,
} from '@oproma/prividox-store';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import flags from 'react-phone-number-input/flags';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { toast } from 'react-toastify';
import {
  Alert,
  Dropdown,
  DropdownMenu,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';

const dateFormatList = [
  { value: 'YYYY/MM/DD', label: 'YYYY/MM/DD' },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
];
const timeFormatList = [
  { value: 'HH:mm', label: 'HH:mm' },
  { value: 'HH:mm:ss', label: 'HH:mm:ss' },
];

// TODO: Break up component
const PersonalPreferencesPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    errors,
    name,
    email,
    dateFormat,
    timeFormat,
    changedName,
    changedPassword,
    changedDateFormat,
    changedTimeFormat,
    loading,
    error,
    changedLocation,
    changedTagline,
    changedPhone,
    address,
    phone,
    tagline,
    language,
    modalTab,
    setModalTab,
    displayModal,
    setDisplayModal,
    setAlertOpen,
    displayPassword,
    setDisplayPassword,
    displayConfirmPassword,
    setDisplayConfirmPassword,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    location,
    nameRef,
    registerName,
    taglineRef,
    registerTagline,
    oldPasswordRef,
    registerOldPassword,
    passwordRef,
    registerPassword,
    confirmedPasswordRef,
    registerConfirmedPassword,
    alertOpen,
    toggleAlert,
    onFormSubmit,
    changeLanguage,
    determineLanguage,
    onDateChange,
    onTimeChange,
    handleEmailClick,
    resetChangedFieldsAndAlert,
  } = usePersonalPreferencesForm();

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey:
      import.meta.env.VITE_GOOGLE_MAPS_API_KEY ??
      'AIzaSyBPEqfrVwkfZsCixZWkq8nxI6U8gVweBkQ',
    options: {
      types: ['(cities)'],
    },
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    if (changedDateFormat) {
      toast.success(t('PREFERENCES.PERSONAL.TOAST.DATE_CHANGED'));
    }
    if (changedTimeFormat) {
      toast.success(t('PREFERENCES.PERSONAL.TOAST.TIME_CHANGED'));
      dispatch(resetTimeFormatChangeFlag());
    }
  }, [changedDateFormat, changedTimeFormat]);

  return (
    <Layout>
      <PreferencesLayout type="personal">
        <PreferencesHeader type="personal" />
        <Block>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">
                {t('PREFERENCES.PERSONAL.BASIC.TITLE')}
              </h6>
            </div>
            <div className="data-item" onClick={() => setDisplayModal(true)}>
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.BASIC.DISPLAY_NAME')}
                </span>
                <span className="data-value">{name}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              onClick={() => {
                setDisplayModal(true);
                setModalTab('2');
              }}
            >
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.BASIC.PASSWORD')}
                </span>
                <span className="data-value">************</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item clickable-row">
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.BASIC.EMAIL')}
                </span>
                <span className="data-value">{email}</span>
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#link"
                  onClick={(ev) => {
                    ev.preventDefault();
                    handleEmailClick();
                  }}
                  className="link link-primary"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t('PREFERENCES.PERSONAL.BASIC.CHANGE_EMAIL')
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">
                {t('PREFERENCES.PERSONAL.PREFERENCES.TITLE')}
              </h6>
            </div>
            <div className="data-item clickable-row">
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.PREFERENCES.LANGUAGE')}
                </span>
                <span className="data-value">
                  {/* TODO: Refactor this */}
                  {language === LanguageEnum.EN ? 'English' : 'Francais'}
                </span>
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#language"
                  onClick={(ev) => {
                    ev.preventDefault();
                    changeLanguage();
                  }}
                  className="link link-primary"
                >
                  {t('PREFERENCES.PERSONAL.PREFERENCES.CHANGE_TO')}{' '}
                  {determineLanguage()}
                </a>
              </div>
            </div>
            <div className="data-item clickable-row">
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.PREFERENCES.DATE_FORMAT')}
                </span>
                <Select
                  options={dateFormatList}
                  className="w-130px"
                  placeholder={dateFormat}
                  isSearchable={false}
                  onChange={(ev) => setValue('dateFormat', ev?.value)}
                />
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#link"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onDateChange();
                  }}
                  className="link link-primary"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t('PREFERENCES.PERSONAL.PREFERENCES.BUTTON')
                  )}
                </a>
              </div>
            </div>
            <div className="data-item clickable-row">
              <div className="data-col">
                <span className="data-label">
                  {t('PREFERENCES.PERSONAL.PREFERENCES.TIME_FORMAT')}
                </span>
                <Select
                  options={timeFormatList}
                  className="w-130px"
                  placeholder={timeFormat}
                  isSearchable={false}
                  onChange={(ev) => setValue('timeFormat', ev?.value)}
                />
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#link"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onTimeChange();
                  }}
                  className="link link-primary"
                >
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    t('PREFERENCES.PERSONAL.PREFERENCES.BUTTON')
                  )}
                </a>
              </div>
            </div>
          </div>
        </Block>
        {/* Preferences Modal */}
        <Modal
          isOpen={displayModal}
          className="modal-dialog-centered"
          size="lg"
          toggle={() => {
            setDisplayModal(false);
            resetChangedFieldsAndAlert();
          }}
        >
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              reset();
              setDisplayModal(false);
              setAlertOpen(false);
              resetChangedFieldsAndAlert();
              setModalTab('1');
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <ModalBody>
            <div className="p-2">
              <h5 className="title">
                {t('PREFERENCES.PERSONAL.BASIC.EDIT_PROFILE')}
              </h5>
              <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${modalTab === '1' && 'active'}`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab('1');
                    }}
                    href="#personal"
                  >
                    Personal
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${modalTab === '2' && 'active'}`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab('2');
                    }}
                    href="#password"
                  >
                    Password
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className={`tab-pane ${modalTab === '1' ? 'active' : ''}`}
                  id="personal"
                >
                  {(error?.code === UserErrorCodesEnum.EDIT_NAME_FAILED ||
                    error?.code === UserErrorCodesEnum.EDIT_LOCATION_FAILED ||
                    error?.code === UserErrorCodesEnum.EDIT_PHONE_FAILED ||
                    error?.code === UserErrorCodesEnum.EDIT_TAGLINE_FAILED) && (
                    <div className="mb-3">
                      <Alert color="danger" className="alert-icon">
                        <Icon name="alert-circle" />{' '}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t(retrieveApiErrorMessage(error.message)),
                          }}
                        ></p>
                      </Alert>
                    </div>
                  )}
                  {(changedName ||
                    changedTagline ||
                    changedLocation ||
                    changedPhone) && (
                    <Alert
                      className="alert-icon"
                      color="success"
                      isOpen={alertOpen}
                      toggle={toggleAlert}
                    >
                      <Icon name="check-circle" />
                      {/* TODO: Move to translation files */}
                      <strong>Completed</strong> - Changes to profile have been
                      persisted!
                    </Alert>
                  )}
                  <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Row className="gy-4">
                      <Col md="6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name">
                            Full Name
                          </label>
                          <div className="form-control-wrap">
                            <Input
                              type="text"
                              id="full-name"
                              placeholder="Enter your full name"
                              innerRef={nameRef}
                              {...registerName}
                              defaultValue={name ?? undefined}
                            />
                            {errors.name && (
                              <span className="invalid">
                                {errors.name.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <div className="form-label-group">
                            <Label className="form-label" for="tagline">
                              Tagline
                            </Label>
                          </div>
                          <div className="form-control-wrap">
                            <Input
                              type="text"
                              id="tagline"
                              placeholder="Enter a tagline"
                              className="form-control"
                              innerRef={taglineRef}
                              {...registerTagline}
                              defaultValue={tagline ?? undefined}
                            />
                            {errors.tagline && (
                              <span className="invalid">
                                {errors.tagline.message}
                              </span>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <div className="form-label-group">
                            <Label className="form-label" for="phoneNumber">
                              Phone number
                            </Label>
                          </div>
                          <PhoneInputWithCountry
                            name="phoneNumber"
                            defaultCountry="CA"
                            control={control}
                            defaultValue={phone ?? undefined}
                            limitMaxLength={true}
                            rules={{ required: false }}
                            flags={flags}
                          />
                          {errors.phoneNumber && (
                            <span
                              className="text-danger"
                              style={{
                                fontSize: '11px',
                                fontStyle: 'italic',
                              }}
                            >
                              {errors.phoneNumber.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-label-group">
                            <Label className="form-label" for="address">
                              Location
                            </Label>
                          </div>

                          <Input
                            type="text"
                            id="address"
                            name="address"
                            className="form-control modal-input"
                            placeholder="Enter your location"
                            defaultValue={address ?? undefined}
                            value={location}
                            onChange={(ev) => {
                              if (!dropdownOpen) {
                                toggle();
                              }
                              getPlacePredictions({
                                input: ev.target.value,
                              });
                              setValue('address', ev.target.value);
                            }}
                          />

                          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownMenu>
                              <ul className="link-list-plain">
                                {placePredictions.map((prediction, index) => (
                                  <li key={index}>
                                    <a
                                      href="#links"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        clearErrors('address');
                                        setValue(
                                          'address',
                                          prediction.description,
                                        );
                                        toggle();
                                      }}
                                    >
                                      <Icon name="map-pin"></Icon>
                                      <span> {prediction.description}</span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </DropdownMenu>
                          </Dropdown>

                          {errors.address && (
                            <span className="invalid">
                              {errors.address.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                          <li>
                            <Button color="primary" size="md" type="submit">
                              {loading ? (
                                <Spinner size="sm" color="light" />
                              ) : (
                                'Confirm Profile Change'
                              )}
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div
                  className={`tab-pane ${modalTab === '2' ? 'active' : ''}`}
                  id="password"
                >
                  {changedPassword && (
                    <Alert
                      className="alert-icon"
                      color="success"
                      isOpen={alertOpen}
                      toggle={toggleAlert}
                    >
                      <Icon name="check-circle" />
                      <strong>Completed</strong> - Your password has been
                      updated!
                    </Alert>
                  )}
                  <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Row className="gy-4">
                      <Col md="12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="current-password"
                          >
                            Current Password
                          </label>

                          <Input
                            type="password"
                            id="current-password"
                            placeholder="Enter current password"
                            innerRef={oldPasswordRef}
                            {...registerOldPassword}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="new-password">
                            New Password
                          </label>
                          <div className="form-control-wrap">
                            <a
                              href="#password"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setDisplayPassword(!displayPassword);
                              }}
                              className={`form-icon form-icon-right passcode-switch ${
                                displayPassword ? 'is-hidden' : 'is-shown'
                              }`}
                            >
                              <Icon
                                name="eye"
                                className="passcode-icon icon-show"
                              ></Icon>

                              <Icon
                                name="eye-off"
                                className="passcode-icon icon-hide"
                              ></Icon>
                            </a>
                            <Input
                              type={displayPassword ? 'text' : 'password'}
                              id="new-password"
                              className={clsx(
                                displayPassword ? 'is-hidden' : 'is-shown',
                              )}
                              placeholder="Enter new password"
                              innerRef={passwordRef}
                              {...registerPassword}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="new-password-confirmation"
                          >
                            Confirm New Password
                          </label>
                          <div className="form-control-wrap">
                            <a
                              href="#password"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setDisplayConfirmPassword(
                                  !displayConfirmPassword,
                                );
                              }}
                              className={`form-icon form-icon-right passcode-switch ${
                                displayConfirmPassword
                                  ? 'is-hidden'
                                  : 'is-shown'
                              }`}
                            >
                              <Icon
                                name="eye"
                                className="passcode-icon icon-show"
                              ></Icon>

                              <Icon
                                name="eye-off"
                                className="passcode-icon icon-hide"
                              ></Icon>
                            </a>
                            <Input
                              type={
                                displayConfirmPassword ? 'text' : 'password'
                              }
                              id="new-password-confirmation"
                              className={clsx(
                                displayPassword ? 'is-hidden' : 'is-shown',
                              )}
                              placeholder="Confirm new password"
                              innerRef={confirmedPasswordRef}
                              {...registerConfirmedPassword}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col size="12">
                        <ul className="align-center flex-sm-nowrap gx-4 gy-2 flex-wrap">
                          <li>
                            <Button
                              color="primary"
                              className="btn-block"
                              type="submit"
                            >
                              {loading ? (
                                <Spinner size="sm" color="light" />
                              ) : (
                                'Confirm Password Change'
                              )}
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </PreferencesLayout>
    </Layout>
  );
};

export default PersonalPreferencesPage;
